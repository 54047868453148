/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/

ion-menu ion-content {
  --background: var(--ion-item-background, var(--ion-background-color, #fff));
}

ion-menu.md ion-content {
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 20px;
  --padding-bottom: 20px;
}
.mobile-view ion-header{
  background-color: var(--ion-background-color);
}
.deposit-money .card-footer > *,.deposit-money .card-footer {
  background-color: var(--ion-background-color);
}
.deposit-money .card-footer select {
  background-color: var(--ion-background-color);
  color: var(--primary-text);
  outline: none
}
.mobile-view .header-menu-bottom {
  padding-top: 10px !important;
}
.account-details-container .avatar {
  margin-bottom: 4em;
}
.mobile-view .search-game .header-search input{
  background-color: var(--ion-background-color) !important;
  --placeholder-color: var(--primary-text) !important;
  color: var(--primary-text)
}

.mobile-view .menu-content  .logged p,
.mobile-view .menu-content  .logged a,
.mobile-view .menu-content  .header-menu-bottom p,
.mobile-view .lang-select .flag-select__option__label {
  color: var(--primary-text) !important
}
.mobile-header ion-toggle {
  width: 67px;
    height: 36px;
}
.mobile-view .flag-select__options {
  background: var(--ion-background-color) !important;
}
.mobile-view  .flag-select__options {
    width: 100% !important;
  }
ion-menu .header-menu-bottom ion-select {
  width: 33px;
  margin-right: 10px;
  margin-left: auto;
}
ion-menu .header-menu-bottom ion-select .select-text {
  display: none !important;
}
.menu-bonus {
  width: 90%;
  padding-left: 3px;
}
ion-menu.md ion-list {
  padding: 20px 0;
}
#inbox-list .lotto-img {
      height: 37px !important;
}
.live-casino-container #live-casino .main-box {
  max-width: 120px !important;
  margin-bottom: 1em;
}
ion-menu.md ion-note {
  margin-bottom: 30px;
}

ion-menu.md ion-list-header, ion-menu.md ion-note {
  padding-left: 10px;
}
.menu-content .logout-btn{
  display: block;
}
#inbox-list ion-label {
    color: #F25D31;
    margin-left: .3em;
    text-transform: uppercase;
    font-family: 'Leckerli One', cursive !important;
    font-weight: normal;
    text-shadow: 0 1px rgba(0,0,0,.3);
}
#inbox-list a {
  color: #F25D31;
  margin-left: .3em;
  text-transform: uppercase;
  font-family: 'Leckerli One', cursive !important;
  font-weight: normal;
  text-shadow: 0 1px rgba(0,0,0,.3);
}
ion-menu.md ion-list#inbox-list {
  border-bottom: 1px solid var(--ion-color-step-150, #d7d8da);
}

ion-menu.md ion-list#inbox-list ion-list-header {
  font-size: 22px;
  font-weight: 600;
  min-height: 20px;
}

ion-menu.md ion-list#labels-list ion-list-header {
  font-size: 16px;
  margin-bottom: 18px;
  color: #757575;
  min-height: 26px;
}

ion-menu.md ion-item {
  --padding-start: 10px;
  --padding-end: 10px;
  border-radius: 4px;
}


ion-menu.md ion-item.selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.md ion-item ion-icon {
  color: #616e7e;
}

ion-menu.md ion-item ion-label {
  font-weight: 500;
}

ion-menu.ios ion-content {
  --padding-bottom: 20px;
}

ion-menu.ios ion-list {
  padding: 20px 0 0 0;
}

ion-menu.ios ion-note {
  line-height: 24px;
  margin-bottom: 20px;
}

ion-menu.ios ion-item {
  --padding-start: 16px;
  --padding-end: 16px;
  --min-height: 50px;
}

ion-menu.ios ion-item ion-icon {
  font-size: 24px;
  color: #73849a;
}

ion-menu.ios ion-item .selected ion-icon {
  color: var(--ion-color-primary);
}

ion-menu.ios ion-list#labels-list ion-list-header {
  margin-bottom: 8px;
}

ion-menu.ios ion-list-header,
ion-menu.ios ion-note {
  padding-left: 16px;
  padding-right: 16px;
}

ion-menu.ios ion-note {
  margin-bottom: 8px;
}

ion-note {
  display: inline-block;
  font-size: 16px;
  color: var(--ion-color-medium-shade);
}

ion-item.selected {
  --color: var(--ion-color-primary);
}
.mobile-view .search-game ion-searchbar{
  margin: 0 auto
}
.mobile-view .search-game {
  width: 90%;
}
.mobile-view .header-md {
  border-bottom: none;
  display: flex;
  justify-content: center;
  align-items: center;
}
#inbox-list div {
  height: 50px;
  width: 65px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: -.3em;
}
.slots-img {
  background-image: url("../../images/videoslots(2).png");
}
.other-img {
  background-image: url("../../images/menu/other.png");
}
.lotto-img {
  background-image: url("../../images/menu/lotto.png");
}
.games-img {
  background-image: url("../../images/menu/games.png");
}
.live-img {
  background-image: url("../../images/menu/live.png");
  background-size: contain !important;
  height: 33px !important;
}
.box-img {
  background-image: url("../../images/bets(1).png");
  height: 43px !important;
}
ion-menu .logged {
  width: 90%;
  position: relative;
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: flex-start;
}
ion-menu .user-menu {
  --inner-padding-end: 0;
  --inner-padding-start: 0;
  --padding-end: 0 !important;
--padding-start: 0 !important;
      width: 111%;
      padding: 0;
    }
ion-menu .login-register-container.outer-links {
    justify-content: center;
    flex-wrap: wrap;
}
ion-menu .logged .logout-btn {
    margin-right: 0;
    margin-left: 0;
    margin-top: 5px;
}
.links-to-menu .logout-btn {
    height: 34px;
    background: #F25D31 0% 0% no-repeat padding-box;
    width: 84px;
    height: 38px;
    margin-top: 10px;
    border-radius: 4px;
    color: #fff;
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    justify-content: center;
}
ion-menu .item-native {
  padding: 0
}
.mobile-view .header-menu-bottom {
      margin-top: 10px !important;
}

#react-root ion-menu .lang-select {
  margin: 0 auto;
  /* display: none; */
  width: 100% !important;
  max-width: 95% !important;
  margin-bottom: 2em;
}
