.latest-container {
  width: 100%;
  margin: 0 auto;
  background: var(--ion-banner-back);
  display: flex;
  justify-content: center;
      align-items: center;
  height: 470px;
}
.latest-container h3, .latest-container h4{
  font-family: 'Leckerli One', cursive !important;
line-height: 40px;
font-size: 40px;
font-weight: 300;
width: -moz-fit-content;
width: -webkit-fit-content;
width: fit-content;
color: #fe9c26;
margin: 0 auto;
align-self: flex-start;
margin-top: 2em;
}
.latest-container h4 {
  font-family: "SF Pro" !important;
  color: #ff4362;
  margin-top: .5em;
  font-size: 50px;
  text-shadow: 0 2px rgba(0,0,0,.2);
}
.latest-container > div {
  width: 50%;
}
.latest-container .line {
  width: 1px;
    height: 80%;
    border-left: 1px solid;
    border-color: var(--ion-typee);
    min-height: 245px;
    opacity: .4;
    margin-top: 25px;
}
.winners  {
  display: flex;
  width: 60%;
  margin: 0 auto;
  align-items: center;
    min-height: 200px;
    flex-direction: column;
}
.winner {
  display: flex;
  width: 100%;
  height: 100%;
  margin: 10px auto;
  align-items: center;
}
.winner .img {
  width: 70px;
  height: 70px;
  background: #777777;
  background-size: cover;
  background-position: bottom;
  border-radius: 50%;
  margin-right: 1em;
}
.winner a {
  margin-left: auto;
  -webkit-text-decoration: none;
          text-decoration: none;

}
.winner .play-now {

  font-size: 20px;
  border: 2px solid;
  border-color: #fe9c26;
  color: #fe9c26;
  width: 100px;
  height: 35px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
  transition: .3s;
  font-family: 'Leckerli One', cursive !important;
}
.winner .play-now:hover {
  box-shadow: 1px 1px 9px rgba(0,0,0,0.5);
}
.dark .winner .play-now:hover {
  box-shadow: 1px 1px 9px rgba(255,255,255,0.5);
}
.winner p {
  margin: 0;
  color: var(--primary-text);
}
.winner .how-much {
  font-weight: bold;
  font-size: 20px;
  color: var(--bold-black)
}
.winners-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  height: 100%;
  min-height: 300px;
      margin-bottom: 50px;
}
.jackpot-container {
  display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    height: 100%;
    min-height: 300px;
    margin-bottom: 50px;
}
