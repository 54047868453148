#user-menu {
  max-width: 377px;
min-width: 304px;
  width: 27%;
  margin-bottom: 35px;
  margin-top: 35px;
  border-right: 1px solid;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}
.user-menu-title {
  font-size: 36px;
  margin: 0;
  /* color: var(--primary-text); */
  color: #F25D31;
  font-family: 'Leckerli One', cursive !important;
  white-space: nowrap;
  cursor: default;
}
.user-cabinet-container .title span{
  color: #F25D31;
  font-family: 'Leckerli One', cursive !important;
}
#react-root .from-user-cabinet {
    --height: 440px;
}
.with-link svg, .with-link img {
  width: auto;
  max-width: 30px;
  height: 30px;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-filter: gray;
          filter: gray; /* IE 6-9 */
  transition: all .5s;
}
.with-link:hover > svg, .with-link:hover > img {
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  -o-filter: none;
  filter: none;
  filter: none; /* IE 6-9 */
}
.user-menu-container .user-menu-link {
  font-size: 20px;
  color: var(--primary-text);
  /* transition: 2s; */
  margin-top: 5px;
  margin-bottom: 5px;
  text-decoration: none;
  margin-left: 7px;
  white-space: nowrap;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  transform: perspective(1px) translateZ(0);
  position: relative;
  overflow: hidden;
}
.user-menu-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-left: 4vw;
  width: 100%;
  margin-bottom: 10px
}
.user-menu-link:hover:before {
    right: 0;
    height: 1px;
}
.user-menu-link:before {
    content: "";
    position: absolute;
    z-index: -1;
    left: 0;
    right: 100%;
    bottom: 0;
    background: var(--primary-text);
    height: 0px;
    transition-property: right;
    transition-duration: 0.3s;
    transition-timing-function: ease-out;
}
@media screen and (max-width: 1366px) {
  #user-menu {
    min-width: 300px;
  }
  .user-menu-container {
    padding-left: 3vw;
  }

}
@media screen and (min-width: 1920px) {
  .user-menu-container {
    padding-left: 76.8px;
  }
}
