/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/
.scroll-container.providers {
  width: auto;
  padding: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.scroll-container.providers a {
  width: 100px;
  /* -webkit-filter: grayscale(100%); */
          /* filter: grayscale(100%); */
  -webkit-box-flex: 1;
      -ms-flex: 1 0 100px;
          flex: 1 0 100px;
          margin-left: 10px;
          margin-right: 10px;
}
.provider-footer img {
  width: 120px;
  height: auto;
  max-height: 100%;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  opacity: .7;
  color: #fff;
}
.provider-footer {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.socials a {
  width: 100%;
  height: 100%;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 62px;
          flex: 0 1 62px;
}
.imgs {
  position: absolute;
  left: 50px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  /* top: 0; */
}
.im-casino {
  background: url('../../images/44.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 80px;
  height: 80px;
}
.to-gov {
  background: url('../../images/crest.svg');
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0.25em;
  width: 60px;
  height: 50px;
  -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
    -webkit-transition: .3s linear;
    transition: .3s linear;
}
.to-gov:hover {
  -webkit-filter: none;
    filter: none;
}
.to-kkk {
  background: url('../../images/kkk.png');
  background-size: contain;
  background-repeat: no-repeat;
  margin: 0.25em;
  width: 60px;
  height: 50px;
  -webkit-transition: .3s linear;
  transition: .3s linear;
}
.to-pic {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 60px;
  padding-top: 1em;
}

.footer-container {
  /* max-width: 1920px; */
  margin: 0 auto;
  position: relative;
  z-index: 10;
  margin-top: 50px;
  background-color: var(--ion-footer);
  border-top: 1px solid #707070;
}
.licensing a {
  color: var(--primary-text)
}
.links-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  border-bottom: 1px solid #707070;
  height: 80px;
  padding: 0em 4em;
}
.visa {
  background: url('../../images/logos/visa.svg');
}
.mastercard {
  background: url('../../images/logos/mastercard.svg');
}
.maestro {
  background: url('../../images/logos/maestro.svg');
}
.paypal {
  background: url('../../images/logos/paypal.svg');
}
.neteller {
  background: url('../../images/logos/neteller.svg');
}
.trustly {
  background: url('../../images/logos/trustly.svg');
}
.sofort {
  background: url('../../images/logos/sofort.svg');
}
.skrill {
  background: url('../../images/logos/skrill.svg');
}
.paysafe {
  background: url('../../images/logos/paysafe.svg');
}
.zimpler {
  background: url('../../images/logos/zimpler.svg');
}
.jeton {
  background: url('../../images/logos/Jeton.svg');
}
.links-top div {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 7%;
          flex: 0 1 7%;
  height: 55px;
}
.socials {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #707070;
  height: 100px;
}
.socials div {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 59px;
          flex: 0 1 59px;
  min-height: 61px;
  min-width: 61px;
  margin: 16px;
  border-radius: 17px;
}
/* .socials .twitter{
  background-image: url('../../images/logos/twi.svg');
} */
/* .socials .facebook{
  background-image: url('../../images/logos/fb.svg');
} */
.socials a div:hover{
  -webkit-box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
          box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
}
/*.socials .instagram{
  background-image: url('../../images/logos/insta.svg');
}*/
/* .socials .telegram{
  background-image: url('../../images/logos/tg.svg');
} */
.licensing h3 {
  font-size: 34px;
  color: var(--primary-text);
  font-family: 'Leckerli One', cursive !important;
  text-transform: capitalize;
}
.licensing {
  border-bottom: 1px solid #707070;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 3em;
  padding-top: 2em;
}
.licensing p{
  text-align: center;
  font-size: 20px;
  /* color: #444444; */
  color: var(--primary-text);
  margin: 0;
}
.links-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-bottom: 1px solid #707070;
  height: 80px;
  padding: 0em 4em;
  width: 100%;
}
.eighteen {
  background: url('../../images/logos/eighteen.svg');
  background-size: contain;
  width: 80px;
  height: 80px;
}.mga {
  background: url('../../images/logos/mga.svg');
}.gambling {
  background: url('../../images/logos/gambling.svg');
}.genesis {
  background: url('../../images/logos/genesis.svg');
}.begambling {
  background: url('../../images/logos/begambling.svg');
}.stodlingen {
  background: url('../../images/logos/stodlingen.svg');
}.responsible {
  background: url('../../images/logos/responsible.svg');
}
.links-bottom div {
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 10%;
          flex: 0 1 10%;
  height: 55px;
  margin: 0 4em;
}
.bottom a {
  font-size:20px;
  text-decoration: none;
  letter-spacing: 0px;
  color: var(--primary-text);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  opacity: 1;
  -webkit-transition: 0.3s;
  transition: 0.3s
}
.bottom a:hover {
  color: #F25D31
}
.bottom {
  height: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  position: relative;

}

.bottom .container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  width: 76%;
  margin: 0 auto;
  margin-top: 1em;
  margin-bottom: 1em;
  max-width: 100%;
  -ms-flex-pack: distribute;
      justify-content: space-around;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.bottom .container div {
  width: auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.logo-ridotto {
  width: 13%;
  height: 80%;
  right: 0px;
  position: absolute;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.logo-ridotto div {
  display: inline-block;
  /* border-left: 1px solid; */
  /* background: url('../../images/logos/logo-fullsize.svg'); */
  /* background-position: center;
  background-repeat: no-repeat;
  background-size: contain; */
  width: 100%;
  min-width: 140px;
  height: 100%;
  margin-left: 5px;
}
.socials div svg > *, .logo-ridotto div svg > *{
  fill: var(--svg-color);
}
.logo-ridotto div svg {
  width: 100%;
  height: 100%;
}
.logo-ridotto p {
  text-align: right;
  margin-right: 10px;
  font-size: 20px;
  color: #444444;
}
.footer-container > div{
  max-width: 1920px;
  margin: 0 auto;
}
.footer-container-top {
  min-width: 100%;
  background-color: #090909;
}
.footer-container .scroll-container {
  max-width: 1920px;
  margin: 0 auto;
}

@media screen and (max-width: 1600px) {
  .bottom .container {
    width: 75%;
  }
}
@media screen and (max-width: 1450px) {
  .bottom a {
    font-size: 20px;
  }
}
@media screen and (max-width: 1370px) {
  .links-bottom div {
    margin: 0 2em;
  }
  .bottom a {
    font-size: 20px;
  }
  .bottom .container {
    /* min-width: 810px; */
  }
}
@media screen and (max-width: 1280px) {
  .links-bottom div {
    margin: 0 1em;
  }
  .bottom .container {
    width: 77%;
  }
  .bottom .container {
    min-width: 750px;
  }
}
@media screen and (max-width: 1024px) {
  .links-bottom div {
    margin: 0 1em;
  }
  .container a {
    font-size: 20px;
  }

  .bottom .container {
    min-width: 700px;
    width: 73%;
  }
  .logo-ridotto {
    right: 16px;
  }
}
