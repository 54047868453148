.bonuses {
  height: auto !important;
  align-items: flex-start !important;
}
.bonuses .winners-container {
  margin-bottom: 0px;
}
.bonuses .content-container {
  margin-top: 0px;
  margin-bottom: 40px;
}
.content-container .bonus:hover .play-now{
  box-shadow: 1px 1px 9px rgba(0,0,0,0.5);
  transition: all .3s ease-out;
  cursor: pointer;
}
.bonus:hover {
    text-decoration: none;
}
.bonus:hover .info h4{
    text-shadow: 0 1px rgba(0,0,0,.3);
}
.play-now {
  margin-left: auto;
}
.play-now {
    font-size: 20px;
    border: 2px solid;
    border-color: #fe9c26;
    color: #fe9c26;
    width: auto;
    min-width: 110px;
    height: 35px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    transition: .3s;
    font-family: 'Leckerli One', cursive !important;
}
.bonus .info {
  margin-left: 0px;
  margin-right: 1em;
}
.bonus {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 0;
  transition: all .3s ease-out
}
.bonus .img, .bonus .img2 {
  background-image: url('../../images/bonus-min1.png');
  background-size: contain;
  background-repeat: no-repeat;
  width: 100px;
  min-width: 120px;
  height: 120px;
  margin: 0;
  margin-right: 1em;
}
.bonus .img2 {
  background-image: url('../../images/bonus-min2.png');
}
.content-container {
  margin: 20px auto;
  width: 100%;
  max-width: 620px;
}
.bonuses h3, .bonuses h4 {
  margin-top: 1em !important;
    transition: .5s;
    text-decoration: none;
}
.bonuses .line {
      align-self: center !important;
          min-height: 310px !important;
}
.bonuses-container .bonus {
      height: -webkit-fit-content;
      height: -moz-fit-content;
      height: fit-content;
}
.bonus ol {
  padding-left: 2em;
}
.bonus .info h3, .bonus .info h4 {
    font-family: 'SF Pro Display', sans-serif !important;
    line-height: 24px;
    font-size: 16px;
    font-weight: 400;
    width: -moz-fit-content;
    width: -webkit-fit-content;
    width: fit-content;
    color: #F25D31;
    margin: 0 !important;
   text-shadow: none;
    align-self: flex-start;
    margin-top: 1em;
    max-width: 380px;
}
.latest-container .bonus .info h4, .jackpot-container .tournament {
  color: var(--primary-text);
}
.bonus  h5 {
  color: #C11916;
}
.bonus .info h3 {
  font-size: 24px;
    text-shadow: 0 2px rgba(0,0,0,.2);
    margin-bottom: 5px !important;
    font-family: 'Leckerli One', cursive !important;
    color: #F25D31;
}
.bonus .info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.bonuses-container .bonus .bonus-content {
  height: 0;
  overflow: hidden;
}
.bonuses-container .bonus .bonus-content.opened {
  height: auto;
}
.bonuses-container .bonus .open-close {
  display: block;
  margin: 0 auto;
  text-align: center;
  box-shadow: none !important;
}
.bonuses-container .open-close.hide-this {
  display: none !important;
}
