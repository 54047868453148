/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/
/* Shine */
.bonus > :first-child > p,
.game .main-box {
  position: relative;
  overflow: hidden;
}
#scroll-anchor {
  height: 1px;
  margin: 0;
}
.lazy-load-image-background.blur {
  width: 100%;
  height: 100%;
}
.lazy-load-image-background.blur.lazy-load-image-loaded > img {
  -webkit-transition: 0.3s !important;
  transition: 0.3s !important;
}
.html-frame iframe {
  max-width: 100% !important
}
ion-toast {
  --color: #fff !important
}
.game .bottom p {
  text-align: center;
}
iframe {
  border: none;
}
ion-modal .ion-page {
  overflow-y: scroll;
	scrollbar-width: none;
}
.ion-page::-webkit-scrollbar {
  width: 0;
}
#egamings_container,
.html-frame {
  height: 100%;
}
/* .bonus > :first-child > p::before,
.game .main-box::before {
  position: absolute;
  top: 0;
  left: -75%;
  z-index: 99999;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
  background: -webkit-gradient(
    linear,
    left top, right top,
    from(rgba(255, 255, 255, 0)),
    to(rgba(255, 255, 255, 0.3))
  );
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 100%
  );
  -webkit-transform: skewX(-25deg);
          transform: skewX(-25deg);
  -webkit-animation: 0.75s ease-in shine;
  animation: 0.7s ease-in shine;
  -webkit-animation-delay: 3s;
  animation-delay: 3s;
}
@-webkit-keyframes shine {
  100% {
    left: 125%;
  }
}
@keyframes shine {
  100% {
    left: 125%;
  }
} */
/* Shine */
.sc-chat-window.opened {
  z-index: 99999;
  max-height: 490px;
  right: 60px;
  bottom: 10px;
}
/* .pies-container {
  -webkit-filter: drop-shadow(0px 0px 6px black);
          filter: drop-shadow(0px 0px 6px black);
} */
.shade-round {
  position: absolute;
  width: calc(65.5% - 2rem);
  height: 87.5%;
  border-radius: 50%;
  margin: 0 auto;
  border: 1px solid;
  left: 20%;
  background: -webkit-gradient(
    linear,
    left bottom, left top,
    from(rgba(0, 0, 0, 1)),
    color-stop(27%, rgba(218, 218, 218, 1)),
    to(rgba(0, 0, 0, 1))
  );
  background: linear-gradient(
    0deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(218, 218, 218, 1) 27%,
    rgba(0, 0, 0, 1) 100%
  );
}
.blog-users-by-device {
  opacity: 0.8;
}
.rltv {
  position: relative;
}
.topbar {
  height: 50px;
  background: #fff;
}
.topbar .provider-logo {
  height: 100%;
}
.apexcharts-tooltip {
  /* background: #f3f3f3; */
  color: #444444;
}
.apexcharts-text.apexcharts-yaxis-label {
  fill: var(--primary-text);
}
.tournament-detail-cms {
  width: 85%;
  margin: 0 auto;
}
.spinner-box .logo {
  background: url("../../images/Logo_bingo_bet_green.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  margin-top: 25vh;
  width: 100%;
  height: 80px;
}
ion-header {
  background-color: var(--ion-footer);
}
.menu-content .logged p,
.menu-content .logged a,
.menu-content .header-menu-bottom p,
.lang-select .flag-select__option__label {
  color: var(--primary-text) !important;
}
body.dark .flag-select__option.has-label:hover .flag-select__option__label {
  color: #444444 !important;
}
.lang-select .flag-select__btn:after {
  border-top: 5px solid var(--primary-text) !important;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
}
#react-root .userActions .header-search .flag-select__options {
  background: var(--ion-dark) !important;
  opacity: 1;
}
.search-game .header-search input {
  background: var(--ion-dark) !important;
  --placeholder-color: var(--primary-text) !important;
  color: var(--primary-text) !important;
}
#header-mobile {
  background-color: var(--ion-dark) !important;
}
.search-game-popover p {
  color: var(--primary-text) !important;
}
.search-game-popover {
  background: var(--ion-dark) !important;
}
.margin-top {
  margin-top: 8em;
}
.transactions-container .graphs {
  margin-top: 20px;
}
.spinner-box {
  width: 100%;
  height: 100%;
  background: var(--ion-background-color);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.spinner-box ion-spinner {
  margin-top: 0px;
  color: #f25d31;
}
.jackpot-roll.slots-pic {
  margin-top: -4em;
}
.jackpot-roll.live-pic {
  margin-top: -7em;
  background-size: 120%;
  background-position: center;
}
.jackpot-roll.lotto-pic {
  height: 321px;
  margin-top: -4em;
}
.jackpot-roll.other-pic {
  background-position: center;
  background-size: 113%;
  margin-top: -2em;
}
.favorites-container .slick-prev,
.favorites-container .slick-next {
  z-index: 99999;
}
.tag-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-right: 2em;
}
.tag-img {
  width: 30px;
  height: 30px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  -webkit-filter: grayscale(100%);
  -moz-filter: grayscale(100%);
  -ms-filter: grayscale(100%);
  -o-filter: grayscale(100%);
  filter: grayscale(100%);
  -webkit-filter: gray;
  filter: gray; /* IE 6-9 */
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
}
.tag-container:hover .tag-img {
  -webkit-filter: none;
  -moz-filter: none;
  -ms-filter: none;
  -o-filter: none;
  filter: none;
  filter: none; /* IE 6-9 */
  cursor: pointer;
}
/* .mobile-view .user-menu .userpic svg {
  position: absolute;
	width: auto;
	height: 30px;

} */
.login-register-container svg {
  position: absolute;
  width: auto;
  height: 30px;
  bottom: 0;
  left: -1px;
  bottom: -15px;
  left: -1px;
  z-index: 9999;
}
.prm .banner-info {
  max-height: 180px !important;
}
.provs .banner-info {
  max-height: 190px !important;
}
.premium-container th svg {
  height: 35px;
  width: auto;
  display: block;
  margin: 0 auto;
}
.premium-container th p {
  margin: 0;
}
.tms .banner-info {
  max-height: 160px !important;
}
.bss .banner-info {
  max-height: 160px !important;
}
body .provs .jackpot-roll {
  margin-bottom: 0;
}
.prm .jackpot-top {
  border: none;
}
.rltv {
  position: relative;
}
.with-link {
  margin: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.image-hidden {
  opacity: 0;
}

.trnments .bonus .info {
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
}
.close-gme {
  width: 40px;
  height: 40px;
  opacity: 0.5;
  position: absolute;
  right: 7px;
  top: 7px;
  z-index: 99999;
  cursor: pointer;
  background-color: #fe9c26;
  background-size: contain;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../images/close.png");
}
.map-general {
  width: 100%;
  height: 400px;
  position: relative;
  z-index: 9999;
}
.trnments .bonus img {
  width: 120px !important;
  min-width: 120px !important;
  border-radius: 5px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.trnments .bonus:hover > div img {
  -webkit-box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
          box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}
.point-map {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-image: url("../../images/favicon.png");
}
.premium-container .card-table {
  margin: 3em auto !important;
  width: 85%;
  max-width: 1620px;
}
.premium-container .table th,
.premium-container .table td {
  text-align: center;
}
.premium-container .first-column {
  text-align: left !important;
  font-weight: bold;
}
.premium-container .table th {
  font-weight: bold;
  color: #c11916;
}
#react-root .paragraph h6 * {
  font-family: "Leckerli One", cursive !important;
  color: #f25d31 !important;
  font-weight: bold;
}
.trnments .bonus .info {
  margin-left: 1em;
  margin-right: 0em;
}
.trnments .content-container {
  margin-top: 10px !important;
}
.fvs .jackpot-roll {
  margin-bottom: 0em;
  margin-top: -5em;
}
.bonuses-container .tournament-single h3 {
  font-size: 30px;
}
#providers .provider-card {
  z-index: 999;
}
.premium-container h3 {
  font-family: "Leckerli One", cursive !important;
  line-height: 40px;
  font-size: 40px;
  font-weight: 300;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  color: #fe9c26;
  margin: 0 auto;
  -ms-flex-item-align: start;
      align-self: flex-start;
  margin-top: 2em;
}
.premium-container li {
  font-size: 18px;
  z-index: 9999;
  position: relative;
}
.benefits {
  font-size: 20px;
  margin-top: 2em;
  margin-bottom: 0;
}
.premium-container .card h3 {
  margin-top: 1em;
}
h1.favs {
  width: 100%;
  text-align: center;
  font-family: "Leckerli One", cursive !important;
  color: #fe9c26;
}
.fvs .jackpot-top {
  border: none;
}
.no-transactions {
  margin-top: 3em;
}

.video-slots-container .game,
.table-games-container .game,
.live-casino-container .game {
  -webkit-animation: none;
  animation: none;
  opacity: 0;
}
.main-page .animate-now,
.games-page .animate-now {
  opacity: 1;
  -webkit-transition: 0.2s;
  transition: 0.2s;
}
.provs .game,
.trnmnts-details .game {
  position: relative;
  opacity: 1 !important;
  -webkit-animation-name: move;
  animation-name: move;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
}
/* .game {
  position: relative;

  -webkit-animation-name: move;
  animation-name: move;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
} */
/* .movenow {
  -webkit-animation-name: move;
  animation-name: move;
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
} */
ion-content .game {
  height: auto;
  -webkit-transition: all .3s ease-out;
  transition: all .3s ease-out;
  -webkit-transform-origin: top center;
          transform-origin: top center;

}
.slick-slider .game .main-box {
  height: auto;
  max-height: 188px;
}
.slick-slider .game .game-preview {
  height: 155px;
}
.video-slots-container,
.table-games-container,
.live-casino-container
 {
  -webkit-perspective: 1500px;
          perspective: 1500px;
  -webkit-perspective-origin: center 100%;
          perspective-origin: center 100%;

}
.animate-now .game {
  position: relative;
  opacity: 1;
  -webkit-animation-name: move;
  animation-name: move;
  -webkit-animation-duration: .3s;
  animation-duration: .3s;
}
@-webkit-keyframes move {
  0% {
    opacity: 0;
    z-index: 8;
    -webkit-transform: translateZ(100px)
    rotate(1deg);
    transform: translateZ(100px)
    rotate(1deg);
  }
  100% {
    z-index: 9;
    opacity: 1;
    -webkit-transform: translateZ(0)
    rotate(0deg);
    transform: translateZ(0)
    rotate(0deg);
  }
}
@keyframes move {
  0% {
    /* bottom: -50px;*/
    opacity: 0;
    z-index: 8;
    -webkit-transform: translateZ(100px)
    rotate(1deg);
    transform: translateZ(100px)
    rotate(1deg);
  }
  100% {
    /* bottom: 0px;*/
    z-index: 9;
    opacity: 1;
    -webkit-transform: translateZ(0)
    rotate(0deg);
    transform: translateZ(0)
    rotate(0deg);
  }
}
.logged .logout-btn {
  background: #f25d31 0% 0% no-repeat padding-box;
  width: 84px;
  height: 38px;
  text-align: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 4px;
  color: #fff;
}
.rcw-conversation-container .rcw-close-button {
  background-color: transparent;
}
.provs #live-casino {
  display: none;
}
.provs .jackpot-top {
  border: none;
}
.provs .live-casino-container {
  padding-top: 0px;
}
.provs .banner-info {
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
}
.rcw-widget-container {
  border: none;
  --side-width: 100%;
}
.sc-user-input {
  z-index: 99999;
}
.rcw-conversation-container .rcw-header {
  padding: 0px 0 25px;
}
.register .modal-container-title {
  opacity: 0.5 !important;
}
#react-root .minimiz {
  display: none !important;
}
#react-root .minimiz.visible {
  margin: 0 auto;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}
.sc-chat-window {
  right: 60px;
  -webkit-transition: 0.1s ease-in-out;
  transition: 0.1s ease-in-out;
}
.sticky div,
.sticky a {
  z-index: 99999;
  color: #fff;
  text-decoration: none;
}
.sticky {
  z-index: 999;
}
.mobile-header .sticky {
  z-index: 99999;
}
.bonuses-container .bonus {
  background: var(--card-color);
}
ion-content .sc-launcher {
  z-index: 9999;
  background: #ff4362;
  position: fixed;
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}
.bonus img {
  width: 90% !important;
  height: auto !important;
  margin: 0 auto !important;
  display: block;
  border-radius: 10px;
}
.tournament-single img {
  border-radius: 5px;
}
.no-margin1 {
  margin-right: 10px !important;
}
.winners-container .bonus span {
  line-height: 24px;
  font-size: 16px;
}
.bonus span {
  font-size: 22px;
  line-height: 1em;
}
.bonuses-container .bonus span {
  font-size: 22px !important;
}
.bonuses-container .bonus h4 span {
  color: var(--bold-black) !important;
}
.slick-dots li button:before {
  color: var(--bold-black) !important;
}
.bonus-content li > * {
  color: var(--bold-black) !important;
}
.bonus-content a {
  color: var(--bold-black) !important;
}
ion-content .bonuses-container .bonus li * {
  font-size: 16px !important;
}
.fvs .game {
  opacity: 1;
}
.bonuses-container .bonus {
  max-width: 450px;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 24%;
          flex: 0 1 24%;
}
.rcw-launcher,
.rcw-conversation-container .rcw-header {
  background-color: #ff4362;
}
body.dark .rcw-launcher {
  -webkit-filter: opacity(80%);
  filter: opacity(80%);
}
#lhc_status_container #status-icon {
  background-color: #ff4362 !important;
}
.bonus > div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.winners-container .bonus img {
  width: 120px !important;
  min-width: 120px !important;
}
.winners-container .bonus .info {
  margin-left: 1em;
}
.winners-container .bonus {
  color: #f25d31;
}
.jackpot-container .tournament {
  color: #f25d31;
  font-family: "SF Pro Display", sans-serif !important;
  /* line-height: 24px; */
  font-size: 16px;
  width: 80%;
  text-align: center;
  margin-top: 2em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.jackpot-container .play-now {
  margin: 0 auto;
}
.bonuses-container .bonus .open-close {
  display: none;
}
.bonuses-container .bonus .bonus-content {
  height: auto;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
ion-content .sc-open-icon,
ion-content .sc-closed-icon {
  -webkit-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
  bottom: 0px !important;
  right: 0 !important;
}
.header-search {
  max-width: 100%;
}
.help-btn {
  -webkit-animation: blink1 5s linear infinite;
  animation: blink1 5s linear infinite;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 90px;
  height: 90px;
}
.pd-top {
  padding-top: 367px;
}
.slick-track {
  /* margin-left: 5px !important; */
}
.slick-track .game {
  margin: 10px 0px;
}
.bonuses-container {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  margin: 2em;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.bonuses-container .bonus {
  position: relative;
  z-index: 99999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 2em;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
          box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.5);
  padding: 2em;
  border-radius: 30px;
}
.bonuses-container .bonus ul {
  padding-left: 1.5em;
}
.bonuses-container .bonus li {
  font-size: 16px;
}
.bonuses-container .bonus h3 {
  font-family: "Leckerli One", cursive !important;
  line-height: 40px;
  font-size: 40px;
  color: #fe9c26;
  text-shadow: 0 2px rgba(0, 0, 0, 0.2);
}
/* .dark .bonuses-container .bonus h3 {
	  color: #fe9c26;
} */
.bonuses-container .bonus .prize-fund {
  color: #f25d31;
  text-align: center;
}
.bonuses-container .bonus h4 {
  /* color: #C11916; */
  text-align: center;
}
.userActions .help-btn p {
  margin: 0;
  font-family: "Leckerli One", cursive !important;
  line-height: 12px;
}
.userActions .help-btn div {
  background-size: cover;
  background-position: center;
  height: 60px;
}
@-webkit-keyframes blink1 {
  0% {
    -webkit-box-shadow: 0px 0px 0px rgba(193, 25, 22, 0.5);
            box-shadow: 0px 0px 0px rgba(193, 25, 22, 0.5);
  }
  50% {
    -webkit-box-shadow: 0px 0px 20px rgba(193, 25, 22, 0.5);
            box-shadow: 0px 0px 20px rgba(193, 25, 22, 0.5);
  }
  100% {
    -webkit-box-shadow: 0px 0px 30px rgba(193, 25, 22, 0.5);
            box-shadow: 0px 0px 30px rgba(193, 25, 22, 0.5);
  }
}
@keyframes blink1 {
  0% {
    -webkit-box-shadow: 0px 0px 0px rgba(193, 25, 22, 0.5);
            box-shadow: 0px 0px 0px rgba(193, 25, 22, 0.5);
  }
  50% {
    -webkit-box-shadow: 0px 0px 20px rgba(193, 25, 22, 0.5);
            box-shadow: 0px 0px 20px rgba(193, 25, 22, 0.5);
  }
  100% {
    -webkit-box-shadow: 0px 0px 30px rgba(193, 25, 22, 0.5);
            box-shadow: 0px 0px 30px rgba(193, 25, 22, 0.5);
  }
}
.game-modal .modal-wrapper {
  width: 90vw !important;
  height: 90vh;
}
.game-modal-container {
  height: 100%;
}
.providers-cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
}
.providers-container {
  height: auto;
  min-height: 0px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  opacity: 0;
  margin-bottom: 0em;
}
.providers-container.shown {
  margin-bottom: 2em;
  min-height: 260px;
  opacity: 1;
}
.provider-card {
  cursor: pointer;
  width: 200px;
  height: 240px;
  background: #ececec;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 10px;
  border-radius: 4px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.provider-card:hover {
  -webkit-box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
          box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}
.provider-card:hover .provider-logo {
  -webkit-filter: none;
  filter: none;
}
.provider-card p {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}
.provider-card .provider-logo {
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  width: 80%;
  height: auto;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
.provider-card .total-games {
  border-radius: 4px;
  background: #ff4362;
  padding: 0 5px;
  color: #fff;
  margin-top: 3em;
}
.provider-card .provider-name {
  margin-bottom: 3em;
  text-transform: uppercase;
  font-family: "SF Pro Display", sans-serif !important;
  color: var(--primary-text);
}
.game .star {
  position: absolute;
  display: block;
  right: 10px;
  top: 5px;
  margin-bottom: 0px;
  z-index: 1000;
}
.game .star ion-icon {
  font-size: 50px !important;
  width: 20px !important;
  color: gold;
  --ionicon-stroke-width: 32px;
  -webkit-animation: 0.5s linear 0s infinite alternate switchwidth;
  animation: 0.5s linear 0s infinite alternate switchwidth;
}
@-webkit-keyframes switchwidth {
  from {
    color: gold;
  }
  to {
    color: #ff4362;
  }
}
@keyframes switchwidth {
  from {
    color: gold;
  }
  to {
    color: #ff4362;
  }
}
.is-current p {
  color: #c11916 !important;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.is-current .tag-img {
  -webkit-filter: none;
  filter: none;
}
body.dark .banner-info {
  background: none !important;
}
body.dark .box,
body.dark .bx,
body.dark #video-slots .main-box,
body.dark #live-casino .main-box,
body.dark #table-games .main-box,
body.dark #lotto .main-box,
body.dark #other .main-box {
  opacity: 0.8;
}
.avatar-prev {
  background-color: var(--ion-backdrop-color);
}
.verify-prev {
  width: 100%;
  height: auto;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}
.terms span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: auto;
}
.terms h2 {
  display: block;
  font-size: 48px;
  color: var(--primary-text);
}
.terms h3 {
  margin-top: 10px;
  margin-bottom: 10px;
  color: #ff4362;
  /* color: var(--primary-text); */
}
.game-opened {
  height: 100%;
}
.run-game-container {
  height: 72vh;
}
.terms p,
.terms p span {
  display: block;
  font-size: 18px !important;
}
.terms p span {
  display: inline;
}
.terms p strong {
  font-weight: bold;
}
.terms li {
  font-size: 18px;
}
.terms {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;
  z-index: 3;
}
.main-page {
  position: relative;
}
.bg-container {
  position: absolute;
  bottom: 0;
  right: 0;
  opacity: 0.2;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 435px;
  width: 600px;
  z-index: 1;
  background-image: url("../../images/Slots.png");
}
.dark .shade {
  opacity: 0.3;
}
.bonus-img1 {
  background-image: url("../../images/bonus1.png");
}
.bonus-img2 {
  background-image: url("../../images/bonus2.png");
}
.terms p span,
.terms span,
.terms p {
  font-family: "SF Pro Display", sans-serif !important;
}
#other .main-box {
  height: 110px;
  margin-bottom: 10px;
}
.bonus-img1,
.bonus-img2 {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 80%;
  height: 350px;
}
.main-page .bg-relative {
  position: relative;
}
.main-page .bg-container {
  position: absolute;
  top: -505px;
  right: 3%;
  opacity: 0.2;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 505px;
  width: 620px;
  z-index: 1;
  background-image: url("../../images/Slots.png");
}
.bonuses-container .bg-container {
  top: 72%;
  z-index: 99;
}
.pic-14 {
  background-image: url("../../images/bgs/14.png");
}
.pic-22 {
  background-image: url("../../images/bgs/22.png");
}
.pic-33 {
  background-image: url("../../images/bgs/33.png");
}
.pic-123 {
  background-image: url("../../images/bgs/123.png");
}
.pic-234 {
  background-image: url("../../images/bgs/565.png");
}
.pic-2324 {
  background-image: url("../../images/bgs/2324.png");
}
.main-page .pic-2324 {
  background-image: url("../../images/bgs/234534.png");
}
.banner-main {
  width: 93%;
  height: auto;
  max-height: 379px;
  max-width: 1920px;
  margin: 0 auto;
  margin-top: -1px;
  margin-bottom: -1px;
  background-size: 100% auto;
  background-position: top;
  background-repeat: no-repeat;
}
.carousel-root {
  max-height: 100%;
  overflow: hidden;
}
.carousel-root .carousel .slide img {
  width: 100%;
}
.carousel-root .carousel .control-dots .dot {
  opacity: 1;
  -webkit-box-shadow: none;
          box-shadow: none;
  border: 1px solid #444444;
  width: 12px;
  height: 12px;
  outline: none;
}
.carousel-status {
  display: none;
}
.carousel-root .carousel .control-dots .dot.selected {
  background: #444444;
}
.dark .day-carousel,
.night-carousel {
  display: none;
}
.dark .night-carousel {
  display: block;
}
.carousel {
  display: none;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.carousel-slider {
  display: block !important;
}
.slide {
  background-color: transparent !important;
}
.banner-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  background: var(--ion-banner-back);
  /* background: linear-gradient(0deg, rgba(255,255,255,1) 1%, rgba(255,255,255,0.7707457983193278) 2%, rgba(218,218,218,1) 32%, rgba(218,218,218,1) 59%, rgba(255,255,255,0.896796218487395) 100%); */
  margin: 0 auto;
  margin-top: -2px;
  margin-bottom: 2em;
}

.banner-info {
  /* background: transparent linear-gradient(180deg, #FFFFFF 0%, #F5F5F5 0%, #E5E5E5 0%) 0% 0% no-repeat padding-box; */
  background: transparent;
  max-width: 1920px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  width: 100%;
  margin: 0 auto;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  padding: 0em 3em;
  margin-top: -2px;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  color: var(--ion-color-banner);
  z-index: 50;
}
.providers {
  -webkit-transition: 0s;
  transition: 0s;
}
.jackpot-title {
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  margin: 0;
  -webkit-transition: all 0.7s ease-out;
  transition: all 0.7s ease-out;
  width: 80%;
  margin-left: 1em;
  min-height: 193px;
  height: 100%;
}
.opnd .jackpot-title {
  min-height: 250px;
}
.jackpot-bottom {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  width: auto;
  max-width: 93%;
  height: auto;
  padding-bottom: 5px;
  margin-top: 1em;
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  overflow: scroll;
  scrollbar-width: thin;
  scrollbar-color: var(--ion-typee) transparent;
}
.jackpot-bottom::-webkit-scrollbar {
  background-color: transparent;
  height: 10px;
  opacity: 0.1;
}
.jackpot-bottom::-webkit-scrollbar-track {
  background-color: transparent;
} /* the new scrollbar will have a flat appearance with the set background color */

.jackpot-bottom::-webkit-scrollbar-thumb {
  background-color: var(--ion-typee);
  border-radius: 5px;
  width: 50px;
} /* this will style the thumb, ignoring the track */

.jackpot-bottom::-webkit-scrollbar-button {
  background-color: transparent;
} /* optionally, you can style the top and the bottom buttons (left and right for horizontal bars) */

.jackpot-bottom::-webkit-scrollbar-corner {
  background-color: transparent;
}
.jackpot-bottom.providers {
  margin-top: 0;
}
.jackpot-top {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;
  border-bottom: 1px solid #707070;
  padding-bottom: 1em;
}
.jackpot-links {
  width: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  max-width: 570px;
}
.jackpot-links a p,
.jackpot-links span p {
  padding: 0px !important;
  font-size: 18px;
  text-transform: uppercase;
  cursor: pointer;
  color: #fe9c26;
  font-family: "Leckerli One", cursive !important;
  /* font-weight: 700; */
  font-weight: normal;
  text-shadow: 0 1px rgba(0, 0, 0, 0.2);
}

.jackpot-links a,
.jackpot-links span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  text-decoration: none;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
  cursor: pointer;
}
.jackpot-links a .bx,
.jackpot-links span .bx {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 90px;
  width: 80px;
}
.jackpot-links .tourney .bx {
  background-image: url("../../images/crown.png");
}
.jackpot-links .premium .bx {
  background-image: url("../../images/VIP.png");
  width: 60px;
}
.jackpot-links .providers .bx {
  background-image: url("../../images/providers.png");
}
.jackpot-links .bonuses .bx {
  background-image: url("../../images/money.png");
  width: 54px;
}

.jackpot-title p {
  margin: 0;
  font-family: "SF Pro" !important;
  font-size: calc(100% + 1.823vw);
  white-space: nowrap;
}
.jackpot-info {
  background: url("../../images/VIP.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin: 0;
  width: 100%;
  height: 268px;
  max-width: 274px;
}
.jackpot-roll {
  -ms-flex-item-align: center;
      align-self: center;
  max-width: 300px;
  min-width: 282px;
  width: 23%;
  height: 215px;
  background: url("../../images/roll.png");
  background-size: cover;
  /* background-position: .5em -2.5em; */
  background-repeat: no-repeat;
  background-position: center;
}
.live-pic {
  background: url("../../images/live.png");
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  height: 362px;
  margin-top: -40px;
}
.slots-pic {
  background: url("../../images/Slots.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.lotto-pic {
  background: url("../../images/Lotto.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.table-pic {
  background: url("../../images/Table.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 353px !important;
  margin-top: -55px;
}
.other-pic {
  background: url("../../images/other_1.png");
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  height: 250px;
}
.bets-pic {
  background: url("../../images/Bets.png");
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  height: 362px !important;
}
.jackpot-title .jackpot-playnow {
  width: 100px;
  height: 32px;
  margin-left: 1em;
  background: url("../../images/play2.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  /* box-shadow: 5px 5px 10px rgba(0,0,0,0.5); */
  -webkit-box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.5);
          box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.5);
}
.dark .jackpot-title .jackpot-playnow {
  -webkit-box-shadow: 0px 3px 4px rgba(255, 255, 255, 0.5);
          box-shadow: 0px 3px 4px rgba(255, 255, 255, 0.5);
}
.jackpot-title .jackpot-playnow:hover {
  -webkit-box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
          box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}
.dark .jackpot-title .jackpot-playnow:hover {
  -webkit-box-shadow: 5px 5px 10px rgba(255, 255, 255, 0.5);
          box-shadow: 5px 5px 10px rgba(255, 255, 255, 0.5);
}
.jackpot-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.jackpot-bottom p {
  padding: 0px !important;
  font-size: 14px;
  cursor: pointer;
  /* margin-right: 2em; */
  /* margin-bottom: 1em; */
  color: var(--ion-typee);
  font-family: "OpenSans" !important;
}
.jackpot-bottom.on-main {
  margin-top: 1em;
}
.jackpot-bottom p:hover,
.jackpot-links a:hover p,
.jackpot-links span:hover p,
.tag-container:hover p {
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  color: #c11916;
}
.main .jackpot-top {
  border: none;
  padding: 0;
}
.banner-info {
  height: auto;
  /* max-height: 215px; */
}
.opnd {
  max-height: 270px;
}
.main .banner-info {
  margin-bottom: 1em;
  height: auto;
  z-index: 10;
  position: relative;
  min-height: 0px;
  max-height: 135px;
}
.main .opnd {
  max-height: 200px;
}

.main .with-hr {
  border-bottom: 1px solid #707070;
  padding-bottom: 1em;
}
.video-slots-container,
.table-games-container,
.live-casino-container,
.game,
.add-games {
  z-index: 9;
  position: relative;
}
.terms p {
  height: 0px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  margin: 0;
  margin-left: 0.8em;
}
.terms .opened p {
  height: auto;
}
.terms p p {
  margin: 0;
}
.terms .paragraph {
  overflow: hidden;
  position: relative;
  z-index: 999;
  margin-top: 10px;
  margin-bottom: 10px;
}
.toscroll {
  width: 100%;
  height: 50px;
}
.terms h3 {
  font-size: 34px;
  /* font-weight: bold; */
  text-align: center;
  /* text-transform: capitalize; */
  font-family: "Leckerli One", cursive !important;
}
.terms .h3-smaller {
  font-family: "Leckerli One", cursive !important;
  text-transform: none;
  cursor: pointer;
  /* font-weight: bold; */
  font-size: 22px;
  text-align: left;
  color: #f25d31;
}
.video-slots-container,
.table-games-container,
.live-casino-container,
.bets-container {
  padding-top: 40px;
  padding-bottom: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  width: 90%;
  max-width: 1920px;
  margin: 0 auto;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  position: relative;
}
.bets-container {
  padding-top: 0px;
}
.video-slots-container .pic-234 {
  bottom: 0;
  top: inherit;
  right: -4%;
  position: absolute;
}
.video-slots-container .bg-container,
.live-casino-container .bg-container {
  position: absolute;
  right: 0%;
  bottom: 0;
}
.video-slots-container,
.table-games-container,
.live-casino-container {
  width: 90%;
  max-width: 1715px;
  display: grid;
  /* grid-template-columns: repeat(6, 1fr); */
  grid-template-rows: repeat(1, 1fr);
  grid-template-columns: repeat(auto-fill, minmax(256px, 1fr));
  grid-gap: 10px !important;
}
.video-slots-container .game,
.video-slots-container #video-slots,
.video-slots-container .add-games,
.table-games-container .game,
.table-games-container #video-slots,
.table-games-container .add-games,
.live-casino-container .game,
.live-casino-container #video-slots,
.live-casino-container .add-games {
  margin: 0px auto;
  width: 100%;
  min-height: 0;
}
.game.doubled {
  grid-column: span 2;
  grid-row: span 2;
  max-width: 100%;
  height: calc(100%);
}
.rcw-conversation-container .rcw-header {
  padding: 0;
}
.rcw-conversation-container .rcw-title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  font-size: 20px;
  color: #fff;
}
.game.doubled .main-box {
  background-position: bottom;
  max-height: 440px;
  height: 88%;
}
.live-casino-container .pic-123 {
  position: absolute;
  background-image: url("../../images/bgs/2324.png");
  bottom: 0;
  top: inherit;
  right: -4%;
}
.table-games-container .pic-33 {
  position: absolute;
  background-image: url("../../images/bgs/234534.png");
  bottom: 0;
  top: inherit;
  right: -4%;
}
#video-slots,
.add-games,
#table-games,
#live-casino,
#other,
#lotto {
  width: 255px;
  height: 170px;
  border-radius: 15px;
  margin: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
#video-slots p,
#table-games p,
#live-casino p,
#other p,
#lotto p {
  font-size: 18px;
  text-transform: uppercase;
  /* font-weight: 700; */
  /* color: var(--ion-typee); */
  color: #ff4362;
  letter-spacing: 0px;
  font-family: "Leckerli One", cursive !important;
  text-shadow: 0 2px rgba(0, 0, 0, 0.2);
  margin: 0;
}
#video-slots .main-box {
  background: url("../../images/videoslots(2).png");
}
#table-games .main-box {
  background: url("../../images/tablegames(2).png");
}
#live-casino .main-box {
  background: url("../../images/menu/live.png");
}
#other .main-box {
  background: url("../../images/menu/other.png");
  max-width: 167px;
  height: 111px;
}
#lotto .main-box {
  background: url("../../images/menu/lotto.png");
  max-width: 167px;
  height: 111px;
}
#video-slots .main-box,
#table-games .main-box,
#live-casino .main-box,
#other .main-box,
#lotto .main-box {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  max-width: 140px;
  width: 100%;
  height: 75px;
}
#other .main-box {
  max-width: 167px;
  height: 111px;
}
#live-casino .main-box {
  max-width: 70px;
}
.add-games,
.game,
#video-slots,
#live-casino,
#table-games {
  max-width: 256px;
  height: 172px;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 22%;
          flex: 1 1 22%;
  /* border: 1px solid #444444; */
  border-radius: 15px;
  margin: 10px;
}
.video-slots-container .add-games,
.video-slots-container .game,
.live-casino-container .add-games,
.live-casino-container .game,
.table-games-container .add-games,
.table-games-container .game {
  max-width: 100% !important;
}
.video-slots-container .game .game-preview,
.live-casino-container .game .game-preview,
.table-games-container .game .game-preview {
  background-size: 100%;
}
#video-slots,
#live-casino,
#table-games {
  border: none;
}
.add-games {
  border: none;
}
.game {
  -webkit-transform: translate3d(0,0,0);
          transform: translate3d(0,0,0);
  cursor: pointer;
  will-change: transform;
  background-size: cover;
  background-position: center bottom;
  background-repeat: no-repeat;
  width: 200px;
  height: 240px;
  background: #ececec;
  border-radius: 5px;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -ms-flex-negative: 1;
      flex-shrink: 1;
}
.dark .game,
.dark .provider-card {
  background: #45474a;
}
.dark .provider-card:hover {
  -webkit-box-shadow: 5px 5px 10px rgba(255, 255, 255, 0.5);
          box-shadow: 5px 5px 10px rgba(255, 255, 255, 0.5);
}
.game .main-box {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  /* max-height: 200px; */
  overflow: hidden;
  width: 93%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
  /* background: url("../../images/Logo_bingo_bet_green.png"); */
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 5px;
  margin: 0 auto;
  margin-top: 10px;
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
}
.game .bottom p {
  max-width: 82%;
  margin: 0;
  color: var(--primary-text);
  font-size: 16px;
}
.game .bottom {
  height: 50px;
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  /* justify-content: space-between; */
  width: 92%;
  margin: 0 auto;
  /* padding-bottom: 10px; */
}
.game .bottom ion-icon {
  position: absolute;
  right: 0;
}
.game {
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  overflow: hidden;
}
.game:hover {
  /* border: none; */
  -webkit-box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
          box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
  background: #444;
}

.game:hover p,
.game:hover ion-icon {
  color: #fff;
}
.game .game-preview {
  position: relative;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  max-height: 100%;
  width: 100%;
  height: 155px;
  left: 0;
  top: 0px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  /* top: calc(100% - 141px); */
  filter: none;
  -webkit-filter: none;
}
.blurred {
  position: absolute;
  width: 100%;
  top: 0px;
  height: 100%;
  background-size: cover !important;
  background-position: center;
  background-repeat: no-repeat;
  filter: blur(8px);
  -webkit-filter: blur(8px);
}
.game:hover .game-preview {
  /* background-size: 120%; */
  -webkit-transform: scale(1.2, 1.2);
          transform: scale(1.2, 1.2);
}
.dark .game:hover {
  background: #444;
  -webkit-box-shadow: 5px 5px 10px rgba(255, 255, 255, 0.5);
          box-shadow: 5px 5px 10px rgba(255, 255, 255, 0.5);
}
.game ion-icon {
  color: #ff4362;
  width: 20px !important;
  z-index: 10;
}
.game .btns {
  position: absolute;
  top: -30%;
  opacity: 0;
  z-index: 0;
  -webkit-transition: all .1s ease-out;
  transition: all .1s ease-out;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.game:hover .btns {
  top: 0;
  height: 100%;
  opacity: 1;
  z-index: 10;
}
.game .play-game {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 5px;
  font-family: "Leckerli One", cursive !important;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 4px;
  background: #f25d31;
  width: 80px;
  height: 38px;
  color: #ffffff;
  font-size: 19px;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.game ion-icon > * {
  z-index: 999;
}
/* .game:hover ion-icon {
  transition: .4s;
  color: #C11916;
  right: 10px;
} */

.game-hovered {
  background: rgba(0, 0, 0, 0);
  width: 100%;
  /* border-radius: 5px; */
  position: relative;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  position: absolute;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  z-index: 1000;
}
.game:hover .game-hovered {
  background: rgba(0, 0, 0, 0.8);
  -webkit-transition: all 0.3s ease-out;
  transition: all 0.3s ease-out;
  color: #f25d31;
}
.add-games .main-box {
  width: 60px;
  height: 60px;
  background: #f25d31;
  /* border: 1px solid #444444; */
  border-radius: 4px;
  cursor: pointer;
}
.game .main-box {
  position: relative;
}
.doubled.game .game-preview {
  height: 100%;
}
.main-box .topleft {
  position: relative;
  width: 100%;
  max-width: 100%;
  min-height: 45px;
  height: 45px;
  top: 0px;
  left: 0;
  background: rgba(255, 255, 255);
  z-index: 999;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.main-box .topleft .provider-logo {
  width: 70px;
  margin: 0 auto;
  /* opacity: 1; */
  position: relative;
  /* max-height: 100%; */
  /* margin-left: 10%; */
}
.policy {
  position: relative;
  padding-bottom: 10em;
  margin-top: 2em;
}
.policy p,
.policy li {
  z-index: 999;
  font-size: 19px;
}
.policy ul {
  margin-top: -1em;
}
.plus {
  font-size: 40px;
  font-weight: bold;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  letter-spacing: 0px;
  color: #ffffff;
  margin: 0 auto;
  -webkit-transition: all 0.2s ease-out;
  transition: all 0.2s ease-out;
  /* margin-top: -2px; */
}
.main-box.plus:hover p {
  font-size: 46px;
}
.main-box.plus:hover {
  border: none;
  -webkit-box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
          box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.5);
}
.dark .main-box.plus:hover {
  border: none;
  -webkit-box-shadow: 5px 5px 10px rgba(255, 255, 255, 0.5);
          box-shadow: 5px 5px 10px rgba(255, 255, 255, 0.5);
}
.main-box .plus {
  margin-left: 0px;
  margin-top: -2.5px;
  width: 100%;
}
.jackpot-text p {
  font-size: 32px;
  font-family: "Leckerli One", cursive !important;
  font-weight: normal;
}
.user-cabinet-container .account-details-container .next-step {
  background: #f25d31 0% 0% no-repeat padding-box;
  border-radius: 4px;
  cursor: pointer;
  height: 38px;
  font-size: 20px !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: #ffffff;
  min-width: 70px;
}
.user-cabinet-container .lower {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 36%;
  min-width: 300px;
}
.account-details-container .submit {
  margin-right: 0;
}
.search-game ion-label {
  min-width: 120px !important;
}
.bonuses-container .tournament-single h3 {
  font-size: 30px;
}
.bonuses-container .tournir-date-start,
.bonuses-container .tournir-date-end,
.bonuses-container .tournir-date-timer {
  margin: 0px auto;
  font-family: "SF Pro Display", sans-serif !important;
  font-size: 20px;
}
.bonuses-container .bg-container {
  bottom: 0;
  top: auto;
}
.bonuses-container .read-more {
  font-size: 20px;
  border: 2px solid;
  border-color: #fe9c26;
  color: #fe9c26;
  width: 130px;
  height: 35px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  cursor: pointer;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  border-radius: 5px;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  position: relative;
  z-index: 999;
  margin: 0 auto;
  margin-top: 1em;
  font-family: "Leckerli One", cursive !important;
}
.bonuses-container .read-more:hover {
  -webkit-box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.5);
          box-shadow: 1px 1px 9px rgba(0, 0, 0, 0.5);
}
.dark .bonuses-container .read-more:hover {
  -webkit-box-shadow: 1px 1px 9px rgba(255, 255, 255, 0.5);
          box-shadow: 1px 1px 9px rgba(255, 255, 255, 0.5);
}
.bonuses-container .tournir-date-start {
  color: var(--primary-text);
}
.dark .tournament-single .tournir-date-start,
.dark .tournament-single .tournir-date-timer {
  color: #ffffff;
}
.dark .was-here-container p,
.dark .was-here-container a {
  color: #ffffff;
  text-shadow: none;
}
.bonuses-container .tournir-date-end {
  color: var(--primary-text);
}
.bonuses-container .tournir-date-timer {
  color: #f25d31;
  margin-bottom: 15px;
}
.bonuses-container .full {
  width: 100%;
}
.bonuses-container .card-table {
  margin: 0em auto !important;
  position: relative;
  max-width: 400px;
  z-index: 9999;
  background: transparent;
}
.bonuses-container b {
  font-weight: bold;
}
.bonuses-container .table td {
  font-weight: bold;
}
.tour .single-tournament h3,
.latest-container h3,
.favorites-container .type,
.licensing h3,
.premium-container h3,
.jackpot-links a p,
.jackpot-text p {
  text-shadow: 0 2px rgba(0, 0, 0, 0.2);
}
.favorites-container {
  position: relative;
  z-index: 999;
}
.terms a,
.terms span {
  color: var(--ion-text-color, #000) !important;
}
.bonuses-container .table thead th {
  color: #f25d31;
  font-size: 18px;
  font-weight: bold;
  font-family: "Leckerli One", cursive !important;
  width: 50%;
  text-align: center;
}
.single-tournament h3 {
  font-family: "Leckerli One", cursive !important;
  line-height: 40px;
  font-size: 40px;
  font-weight: 300;
  width: -moz-fit-content;
  width: -webkit-fit-content;
  width: fit-content;
  color: #fe9c26;
  margin: 0 auto;
  margin-bottom: 1em;
  position: relative;
  z-index: 2;
}
.single-tournament h4 {
  color: #c11916;
  z-index: 2;
}
.tour .single-tournament h4 {
  font-family: "Leckerli One", cursive !important;
  font-size: 30px;
  margin-top: 10px;
}
.single-tournament p,
.single-tournament li {
  font-size: 18px;
  position: relative;
}
.tour .video-slots-container {
  width: 100% !important;
}
.single-tournament {
  width: 90%;
  margin: 0 auto;
}
.tour .bg-container {
  z-index: 1 !important;
}
.single-tournament ul {
  padding-left: 20px;
}
.tournament-single .image {
  width: 100%;
  margin-bottom: 1em;
  /* border: 1px solid grey; */
}

.single-tournament .prize-fund {
  color: #c11916;
}
.single-tournament .tournir-date-start,
.single-tournament .tournir-date-end,
.single-tournament .tournir-date-timer {
  text-align: center;
}
.tour .single-tournament h3 {
  margin-bottom: 0.5em;
}
.left-td {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
td.first-place,
td.first-place .place {
  color: #ffd700;
  font-family: "SF Pro" !important;
}
td.second-place,
td.second-place .place {
  color: #c0c0c0;
  font-family: "SF Pro" !important;
}
td.third-place,
td.third-place .place {
  color: #cd7f32;
  font-family: "SF Pro" !important;
}
td div.no-img {
  width: 30px;
  height: 30px;
  background-image: url("../../images/medals/gold.svg");
  opacity: 0;
}
td div.first-place {
  width: 30px;
  height: 30px;
  background-image: url("../../images/medals/gold.svg");
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 1;
}
td div.second-place {
  width: 30px;
  height: 30px;
  background-image: url("../../images/medals/silver.svg");
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 1;
}
td div.third-place {
  width: 30px;
  height: 30px;
  background-image: url("../../images/medals/bronze.svg");
  background-size: contain;
  background-repeat: no-repeat;
  opacity: 1;
}
td div.place {
  width: auto;
  margin-left: 0.3em;
}
.bonuses-container .table td {
  text-align: center;
}
.tournament-detail-cms h4 > * {
  font-family: "Leckerli One", cursive !important;
  z-index: 9999;
  position: relative;
}
#react-root .paragraph h6 span {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.bonuses-container .table tbody {
  /* display: flex;
  justify-content: space-around; */
}

@media screen and (min-width: 1919px) {
  .jackpot-links {
    margin-right: 6em;
  }
  body .jackpot-roll {
    max-width: 356px;
    width: 356px;
    height: 265px;
    margin-left: 0.5em;
    margin-bottom: -4em;
  }
  body .main .jackpot-roll {
    margin-bottom: -1em;
  }
  body .live-pic {
    /* width: 333px; */
    background-size: cover;
    background-position: center;
    height: 390px;
  }
  body .other-pic {
    height: 282px;
    background-size: cover;
    background-position: center;
  }
  .banner-main {
    height: 390px;
  }
  .jackpot-text p {
    font-size: 36px;
  }
  .jackpot-title .jackpot-playnow {
    width: 119px;
    height: 38px;
    margin-left: 1em;
  }
  .jackpot-info {
    max-width: 274px;
  }
  #video-slots .main-box,
  #table-games .main-box,
  #live-casino .main-box {
    background-size: contain;
    background-position: center !important;
    background-repeat: no-repeat;
    width: 180px;
    height: 100px;
    max-width: 200px;
  }
  #table-games .main-box {
    max-width: 170px;
  }
}
@media screen and (max-width: 1490px) {
  .jackpot-text p {
    font-size: 28px;
  }
}
@media screen and (max-width: 1340px) {
  .jackpot-links {
    /* max-width: 430px; */
  }
}
@media screen and (max-width: 1280px) {
  .jackpot-info {
    max-width: 224px;
    margin: 0;
  }
  .tournament-detail-cms {
    width: 100%;
    margin: 0 auto;
  }
  .jackpot-title .jackpot-playnow {
    margin-left: 10px;
  }
  .banner-info {
    padding: 0em 1em;
  }
}
@media screen and (max-width: 1211px) {
  .jackpot-text p {
    font-size: 24px;
  }
  .jackpot-links {
    /* max-width: 390px; */
  }
  .jackpot-links a p {
    font-size: 16px;
  }
}
@media screen and (max-width: 1155px) {
  .jackpot-roll {
    background-size: contain;
    background-position: center;
    min-width: auto;
  }
  .other-pic {
    height: 260px;
  }
}
@media screen and (max-width: 1090px) {
  .jackpot-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  .jackpot-title .jackpot-playnow {
    margin-left: 0px;
  }
  .jackpot-links {
    max-width: 435px;
  }
  .jackpot-links {
    width: 56%;
  }
}
@media screen and (min-width: 1024px) {
  .jackpot-links.bnss {
    max-width: 420px;
  }
}
@media screen and (min-width: 1025px) {
  .game.doubled .main-box {
    height: 89%;
    max-height: -webkit-fit-content;
    max-height: -moz-fit-content;
    max-height: fit-content;
  }
}
@media screen and (max-width: 993px) {
  .game.doubled {
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    max-height: unset;
  }
  .game .bottom p {
    margin: 0;
    max-width: 95%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .game .bottom ion-icon {
    position: relative;
  }
}
@media screen and (max-width: 500px) {
  .terms .paragraph {
    margin: 0 auto;
    width: 90%;
  }
  ion-content .terms .h3-smaller {
    font-size: 22px;
  }
  .terms p,
  .terms p span {
    font-size: 16px !important;
  }
  .user-cabinet-container .lower {
    min-width: 100%;
  }
  .user-cabinet-container .dates .header-search {
    margin-left: 0px;
  }
  .login-modal .incorrect-password {
    width: auto;
  }
}
@media screen and (min-width: 1280px) {
  .providers-cards {
    width: 90%;
    margin: 0 auto;
  }
}
