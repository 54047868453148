@import url('https://fonts.googleapis.com/css2?family=Leckerli+One&display=swap');
.favorites-container {
  width: 100%;
  height: auto;
  max-width: 1500px;
  margin: 0 auto;
  margin-bottom: 2em;
}
.go-to-favs {
  background: #F25D31 0% 0% no-repeat padding-box;
    width: auto;
    cursor: pointer;
    height: 38px;
    max-width: 140px;
    border-radius: 4px;
    color: #fff;
    font-size: 20px;
    /* border: 1px solid #444444; */
    margin-left: 50px;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-decoration: none;
    justify-content: center;
    margin: 0 auto;
    margin-top: 35px;
    font-family: 'Leckerli One', cursive !important;
}
.go-to-favs:hover {
  box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
    transition: all .3s ease-out;
}
.slick-track {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.slick-slider {
  position: relative;
}
.slick-list {
  width: 90%;
  /* max-width: 1920px; */
      height: 260px;
  margin: 0 auto !important;
}
.favorites-container .game{
  /* width: 50% !important; */
}
.slick-slide {
  /* width: 14vw !important;
  max-width: 256px; */
  /* margin-right: 6px;
  margin-left: 6px; */
}
.slick-prev:before, .slick-next:before {
  content: none !important;
}
.favorites-container .slick-prev {
    left: 0px;
    background-image: url('../../images/left-arrow1.png');
    background-size: contain;
    background-repeat: no-repeat;
}
.favorites-container .slick-next {
    right: 0px;
    background-image: url('../../images/next1.png');
    background-size: contain;
    background-repeat: no-repeat;
}
.favorites-container .slick-prev, .favorites-container .slick-next {

  border-radius: 50%;
  /* color: #fff; */
  font-size: 40px;
  cursor: pointer;
  /* display: flex !important; */
  justify-content: center;
  align-items: center;
  pointer-events: all;
  width: 32px;
  height: 32px;
}
.favorites-container .slick-prev {
    left: 0px;
    transition: .3s;
}
.favorites-container .slick-next {
    right: 0px;
    transition: .3s;
}

.favorites-container .slick-next:hover,
.favorites-container .slick-next:focus {
  background-image: url('../../images/next1.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.favorites-container .slick-prev:hover,
.favorites-container .slick-prev:focus {
  background-image: url('../../images/left-arrow1.png');
  background-size: contain;
  background-repeat: no-repeat;
}
.favorites-container .slick-next:hover,
.favorites-container .slick-prev:hover {
  box-shadow: 5px 5px 10px rgba(0,0,0,0.5);

}
.dark .favorites-container .slick-next:hover,
.dark .favorites-container .slick-prev:hover {
  box-shadow: 1px 0px 13px rgba(255,255,255,0.5)

}
.favorites-container .type, .favorites-container .type-description{
  font-family: 'Leckerli One', cursive !important;
  line-height: 40px;
  font-size: 40px;
  font-weight: 300;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  color: #fe9c26;
  margin: 0 auto;
}
.favorites-container  .type-description {
font-family: 'SF Pro Display', sans-serif !important;
font-size: 20px;
font-style: italic;
color: var(--primary-text);
font-weight: bold;
}
/* .favorites-container ion-toggle {
  --background: #000;
  --background-checked: #7a49a5;

  --handle-background: #7a49a5;
  --handle-background-checked: #000;
} */
.favorites-container .selector {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 0 auto;
}
.favorites-container ion-toggle::after {
  position: absolute;
  top: 8px;
    left: 7px;
  content: "";
  width: 14px;
  height: 14px;
  display: block;
  background: url("../../images/star(1).svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  font-weight: bold;
}
.favorites-container ion-toggle::before {
  position: absolute;
  top: 8px;
    right: 6px;
  content: "";
  width: 15px;
  height: 15px;
  background: url("../../images/heart.svg");
  color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}
.favorites-container ion-toggle.toggle-checked::before {
  background: url("../../images/heart(1).svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.favorites-container ion-toggle.toggle-checked::after {
  background: url("../../images/star.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.favorites-container ion-toggle {
  width: 60px;
  height: 30px;
  padding: 0px;
  background: #ddd;
  cursor: pointer;
  border-radius:15px;
  border: 2px solid #c3c3c3;
  --background: var(--ion-footer);
      margin-left: -29px;
  --border-radius:15px;
  --background-checked: var(--ion-footer);
  --handle-checked: #000;
  --handle-height: 28px;
  --handle-width: 28px;
  --handle-border-radius: 50%;
  --handle-background: #fe9c26;
  --handle-background-checked: #ff4362;
}
.favorites-container  .fvs {
  color: #ff4362;
}
.slick-dots {
  z-index: 99999;
}
.nofavs {
  margin: 2em auto;

  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.game:focus, .slick-slide {
  border: none;
      outline: none;
}
