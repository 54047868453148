/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/
.sticky {
  position: absolute;
  /* border: 1px solid; */
  display: flex;
  right: 0px;
  height: 100%;
    width: 50px;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.sticky div {
position: fixed;
padding: 7px 10px;
background: #00ebff;
min-width: 80px;
margin-top: 40px;
margin-bottom: 20px;
transform: rotate(-90deg);
transform-origin: bottom right;
border-radius: 4px;
box-sizing: border-box;
cursor: pointer;
pointer-events: all;
text-align: center;
transition: .3s;
color: #fff;
}

.sticky .help-sticky {
  bottom: 200px;
  background: #888888;
}
.sticky .login {
  bottom: 290px;
}
.sticky .sign-up {
  bottom: 380px;
  background: #fe9c26;
}
.sticky .sign-up  a {
  text-decoration: none;
  color: #fff;
  width: 100%;
  height: 100%;
}
.flag-icon {
  width: 50px;
  height: 50px;
  background: red;
}
 .verific .username{
  margin-top: 10px !important;
  margin-bottom: 10px !important;

}
.searchbar-input-container {
  display: flex !important;
  --box-shadow: none !important;
  overflow: hidden;
}
.searchbar-search-icon.sc-ion-searchbar-md {
  position: relative;
      left: 0;
}
.searchbar-clear-button.sc-ion-searchbar-md {
  display: none !important;
}
.searchbar-input-container input {
  -webkit-padding-start: 5px !important;
          padding-inline-start: 5px !important;
          -webkit-padding-end: 5px !important;
                  padding-inline-end: 5px !important;
}
.verific .modal-container-title {
  margin-bottom: .5em !important;
}
#react-root .verific {
  --height: 500px;
}
.logged .userpic {
  position: relative;
  width: 70px;
  margin-right: 5px;
  min-width: 70px;
  height: 70px;
  background-size: cover !important;
  background-position: center;
  background-repeat: no-repeat !important;
  border: 1px solid #707070;
  border-radius: 50%;
}
.userpic svg {
  position: absolute;
    width: auto;
    height: 30px;
    bottom: 0;
    left: -5px;
}
.restore {
  cursor: pointer;
}
.recvr .username {
    margin-top:4em;
}
.recvr .restore-p{
  margin-top: 2em;
}
.lang-select .flag-select__option__label {
    font-family: "SF Pro Display" !important;
    font-size: 16px !important;
    width: auto;
    color: #444444 !important;
}
.home-link {
  --ripple-color: transparent;
}
 #react-root .userActions .header-search .has-label{
       height: 29px;
   margin: 0;
   padding: 0 !important;
   padding-left: 1em !important;
 }
  #react-root .userActions .header-search .flag-select__options {
    padding: 0px;
    padding-top:3px;
    list-style-type: none;
    width: 100% !important;
    margin-top: 0;
    z-index: 999999999;
    top: 37px !important;
    position: absolute;
    background: #fff !important;
    border-radius: 4px;
  }
.help-btn {
  cursor: pointer;
  position: fixed;
  bottom: 20px;
  text-decoration: none !important;
  left: 10px;
  width: 90px;
  height: 90px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background: var(--ion-semitransparent)
}
.userActions .help-btn div {
  background: url("../../images/help.png");
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  width: 106px;
  height: 77px;
}
.userActions .help-btn p {
  margin-top: -15px;
  font-family: "SF Pro" !important;
  font-weight: bold;
  color: #C11916 !important;
  font-size: 16px;
}

/* .help-btn::after {
  display: block;
  content: "HELP";
  position: relative;
  top: 62px;
  left: 35px;
  font-weight: bold;
  color: #F25D31;
  font-size: 16px;

  font-family: "SF Pro" !important
} */

.logo-darkmode-container ion-toggle::after,
.mobile-header ion-toggle::after  {
  position: absolute;
  top: 8px;
  left: 8px;
  content: "";
  width: 20px;
  height: 20px;
  display: block;
  background: url("../../images/day.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  font-weight: bold;
}

.logo ion-item {
  --background: none;
  --background-activated: none;
  --background-hover: none;
  --background-focused: none;
  height: 100%;
  --min-height: 100%;
}
.logo-darkmode-container ion-toggle::before,
.mobile-header ion-toggle::before {
  position: absolute;
  top: 8px;
  right: 7px;
  content: "";
  width: 20px;
  height: 20px;
  background: url("../../images/night.svg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  z-index: 1;
}

/* .logo-darkmode-container .toggle-checked::after {
    background: none;
} */
.logo-darkmode-container ion-toggle,
.mobile-header ion-toggle {
  width: 73px;
  height: 36px;
  padding: 0px;
  border-radius: 4px;
  border: 1px solid #707070;
  --background: #F2F2F2;
      margin-left: -29px;
  --border-radius: 4px;
  --background-checked: #444444;
  --handle-height: 34px;
  --handle-width: 36px;
  --handle-background-checked :#888888;
  --handle-border-radius: 4px;
}
.dark .logo-darkmode-container ion-toggle::before,
.dark .mobile-header ion-toggle::before {
  background: url("../../images/night0.svg");
  background-repeat: no-repeat;
}
.dark .logo-darkmode-container ion-toggle::after,
.dark .mobile-header ion-toggle::after {
  background: url("../../images/day0.svg");
  background-repeat: no-repeat;
}
ion-toolbar {
  --border-style: none;
}
.toggle-inner {
  height: 43px;
}
.icon-search {
  position: absolute;
  width: 24px;
  right: 5px;
  cursor: pointer;
  height: 24px;
  background: url('../../images/search-24px.svg');
}
.toolbar-contents .logo-darkmode-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 0 1 35%;
}
.logo-darkmode-container .top-container {
  margin-top: 26px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 100%;
}
ion-header {
  /* max-width: 1920px; */
  margin: 0 auto;
  background-color: #fff;
}
.logo-darkmode-container .logo {
  width: 90%;
  max-width: 553px;
  height: 159px;
  margin-top: 8px;
  background: url('../../images/Logo_bingo_bet_green.png');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
.toolbar-contents {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}
.toolbar-container {
  height: 230px;
  width: 100%;
  max-width: 1920px;
  margin: 0 auto;
  /* padding-top: 1.7em !important; */
}
.header-search {
  width: 100%;
  max-width: 298px;
  height: 38px;
  border: 1px solid #707070;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  opacity: 1;

}
.search-game {
    position: relative;
}
.search-game .header-search {
    overflow: hidden;
}
.search-game .header-search input {
  background: #fff;
  --placeholder-color: #262626 !important;
  color: #444444;
}
.search-game-popover {
  position: absolute;
  width: 102%;
  max-height: 70VH;
  overflow-y: scroll;
  background: #fff;
  z-index: 999999;
  border-radius: 4px;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.search-game-popover .game-image{
  width: 70px;
  height: 40px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 4px
}
.search-game-popover div{
  display: flex;
  align-items: center;
  width: 90%;
  margin-top: 5px;
  margin-bottom: 5px;
  color: #444444;
  text-decoration: none;
  font-size: 18px;
}
.search-game-link:hover p{
  color: #F25D31 !important;
  text-decoration: none;
}
.search-game-link {
  margin-left: 5px;
  cursor: pointer;
  z-index: 999999;
}
.mobile-view .flag-select__btn:before {
    z-index: 1;
}
.search-game-popover p{
  margin: 0;
  margin-left: .5em;
}
.header-search-container {
  margin-right: -5px;
  max-width: 298px;
  width: 47%;

}
.header-search-container ion-input {
  position: relative;
  width: 100%;
  --color: #444444;
  --padding-end:30px;
  --placeholder-color: #000;
  --placeholder-font-size: 20px;
  --padding-start:10px;
  --padding-top: 0;
  --padding-bottom: 0;
  padding-top: 0;
  padding-bottom: 0;
  line-height: 21px;
}
.header-search-container input {
  display: flex;
  justify-content: center;
  padding-right: 20px;
  align-items: center;
  text-align: center;
  line-height: 21px;
}
.header-search-container input::-webkit-input-placeholder {
  font-size: 20px;
}
.header-search-container input::-moz-placeholder {
  font-size: 20px;
}
.header-search-container input:-ms-input-placeholder {
  font-size: 20px;
}
.header-search-container input::-ms-input-placeholder {
  font-size: 20px;
}
.header-search-container input::placeholder {
  font-size: 20px;
}
.userActions {
  width: 21.1%;
  height: 220px;
  display: flex !important;
}
.login-register-container {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  margin: 10px 0px;
}
.register-btn {
  width: 80px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-wrap: nowrap;
  font-size: 19px;
  white-space: nowrap;
  letter-spacing: 0px;
  color: #FFFFFF;
  opacity: 1;
  --inner-padding-start: 0;
  --padding-start: 0;
  --inner-padding-end: 0;
  --background: #F25D31 0% 0% no-repeat padding-box;
  /* border: 1px solid #707070; */
  border-radius: 4px;
  cursor: pointer;
  margin: 0;
}
.userActions .login-btn {
  border-radius: 4px;
  background: none !important;
  --background: none !important;
}
ion-label {
    transition: .3s;
}
.userActions .login-btn ion-label {
    --color: var(--primary-text) !important;
}
.login-register-container.unlogged .login-btn {
  color: var(--primary-text) !important;
}
.login-btn {
  font-size: 20px;
  width: 80px;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  margin: 0;
  color: var(--primary-text) !important;
}
.logged .logout-btn {
  margin-right: 1%;
  margin-left: 2%;
}
.flag {
  width: 50px;
  height: 50px;
  border: 1px solid;
}
.userActions .lang-select .country {
  width: 100%;
  max-width: 215px;
  --color: #444444 !important;
  --placeholder-color: #444444 !important;
  --placeholder-opacity: 1;
  color: #444444 !important;
  font-size: 20px;
  opacity: 1;
  line-height: 24px;
  --padding-bottom: 0px;
  --padding-top: 0px;
}
.sc-ion-select-popover-md {
  padding: 0px;
}
.lang-select {
  margin: 10px 0px;
  margin-top: 0px;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
.gplay {
  width:auto;
  /* max-width: 162px; */
  height: auto;
  margin-right: 2px;
  border-radius: 5px;
  display:flex;
  align-items: center;
  justify-content: center;
}
.appstore {
  width: auto;
  /* max-width: 144px; */
  height: auto;
  border-radius: 10px;
}
.outer-links img {
  height: 43px;
}
.userActions a {
  cursor: pointer;
}
.buttons-last-slot .userActions {
  margin: 0;
}
.userActions .addmoney, .userActions .balance {
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
}
.userActions .center {
  padding: 3px 10px;
  margin-right: 5px;
}
.userActions .toolbar-container {
  overflow: visible;
}
.userActions {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;

}
.userActions .logged {
    padding-top: 1.3em;
    align-self: flex-end;
    max-width: 325px;
}
.toggle-icon {
  border: 1px solid #707070;
}
.sc-ion-modal-md-h {
    --width: 678px;
    --height: 435px;
}
.modal-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  align-items: center;
  overflow: hidden;
}
.cards {
  z-index: 0;
  height: 70%;
  width: 80%;
  position: absolute;
  bottom: 0;
  right:-8em;
  background: var(--login-bg);
  background-size: cover;
  background-repeat: no-repeat;
}
.logout .cards {
  bottom: -2em;
}
.form-login {
  position: relative;
}
.login-modal .header-search {
  width: 472px;
  flex: 0 0 40px;
}
.logreg .modal-container {
  background: var(--ion-login-bg)
}
.modal-container-title {
  height: 86px;
  width: 100%;
  background: var(--ion-login-header);
  border-bottom: 1px solid #707070;
  display: flex;
  z-index: 1;
  align-items: center;
  justify-content: center;
  color: var(--ion-login-title);
  font-size: 64px;
  /* font-weight: bold; */
  /* font-family: 'SF Pro' !important; */
}
.username {
  margin-top: 65px;
}
.password {
  margin-top: 36px;
}
.modal-container {
  border:1px solid #707070;
}
.login-modal .login-btn {
  margin-top: 36px;
  width: 470px;
  height: 48px;
  background: #F25D31 0% 0% no-repeat padding-box;
  border-radius: 4px;
  font-size: 26px;
  color: #F2F2F2 !important;
  z-index: 1;
}
.login-modal p {
  margin-top: 11px;
  font-size: 20px;
  color: #444444;
  z-index: 1;
}
.modal-wrapper input{
  background: #F2F2F2 0% 0% no-repeat padding-box !important;
  line-height: 21px;
}
.modal-wrapper input::-webkit-input-placeholder {
  font-size: 20px;
}
.modal-wrapper input::-moz-placeholder {
  font-size: 20px;
}
.modal-wrapper input:-ms-input-placeholder {
  font-size: 20px;
}
.modal-wrapper input::-ms-input-placeholder {
  font-size: 20px;
}
.modal-wrapper input::placeholder {
  font-size: 20px;
}
.logo a {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
.login-modal .incorrect-password {
  margin: 0;
  font-size: 14px;
  width: 472px;
  text-align: left;
  color: #F25D31;
  display: none;
}
.incorrect .incorrect-password {
  display: block;
}
.incorrect .header-search {
  border-color: #F25D31
}

.unlogged .login-btn, .unlogged .register-btn {
  margin-left: 10px;
}
.logged p, .logged a {
  margin: 0;
  font-size: 20px;
  text-decoration: none;
  letter-spacing: 0px;
  color: #444444;
  cursor: pointer;
}
.logged {
  margin-bottom: 0;
  margin-top: -.8em;
  display: flex;
  justify-content: space-between;
}
.logged .name {
  margin-right: 0%;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.logged .name:hover {
  color: #444444;
}

.login-modal .logout-p {
  margin-top: 4em;
  color: #444444;
}
.unlogged .register-btn {
  margin-left: 5px;
  margin-right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F25D31
}
.unlogged .item-inner {
  padding-right: 0px !important;
}
.unlogged {
  margin-top: 30px;
}
.minimize {
  position: fixed;
}
.minimize .logo,
.minimize .userActions .header-search-container,
.minimize .userActions .flag-select,
.minimize .userActions .outer-links,
.minimize .themeToggle,
.minimize .header-menu-bottom{
  display: none;
}
.minimize .userActions, .minimize .toolbar-container {
  height: auto;
}
.minimize .toolbar-contents {
  align-items: center;
}
.minimize .logo-darkmode-container {
      flex: 0 1 25%;
}
@media screen and (min-width: 1920px) {
   body .top-container .header-search-container{
    margin-right: -13px;
  }
  body .logo-darkmode-container .top-container {
    margin-top: 24px;
  }
  body .logo-darkmode-container {
    width: 636.078px;
  }
  body #header-menu {
    flex: 0 1 50%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    margin-left: 0em;
    margin-right: 0em;
  }
  body .bets-red {
    max-width: 92px !important;
  }
 .header-menu-top {
  display: flex;
  width: 100%;
  justify-content: space-between;
  }

  .logged .name {
    margin-right:0%;
  }
  .toolbar-container {
    height: 228px;
  }
  .logged .logout-btn {
    margin-left: 2.5%;
  }
}
@media screen and (max-width: 1366px) {
  .toolbar-contents .logo-darkmode-container{
    flex: 0 1 28.6%;
  }
  #header-menu .header-menu-top a {
    font-size: 16px;
  }
  .header-search-container ion-input input::-webkit-input-placeholder {
    font-size: 14px;
  }
}
@media screen and (max-width: 1025px) {
  .login-btn, .register-btn {
  font-size: 16px;
  }
  .userActions {
    height: 210px;
  }
  .header-menu-bottom {
    margin-top: -6px !important;
  }
  .header-menu-bottom .deposit-money-button {
        margin-left: 20px;
  }
  .coins {
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }
  .header-search-container {
    width: 50%;
  }
}
