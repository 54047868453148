@font-face {
    font-family: SF Pro Display;
    src: url(./fonts/SF-Pro-Display-Light.otf); /* Путь к файлу со шрифтом */
   }
@font-face {
   font-family: SF Pro;
   src: url(./fonts/SFProDisplay-Bold.ttf); /* Путь к файлу со шрифтом */
  }
@font-face {
   font-family: SF Pro Medium;
   src: url(./fonts/SFProDisplay-Medium.ttf); /* Путь к файлу со шрифтом */
  }
  @font-face {
     font-family: SF Pro Thin;
     src: url(./fonts/SFProDisplay-Thin.ttf); /* Путь к файлу со шрифтом */
    }
    @font-face {
       font-family: SF Pro Semibold;
       src: url(./fonts/SFProDisplay-Semibold.woff2); /* Путь к файлу со шрифтом */
      }
@font-face {
  font-family: OpenSans;
  src: url(./fonts/OpenSans-SemiBold.ttf); /* Путь к файлу со шрифтом */
}
.jackpot-value {
  position: absolute;
color: #fff;
bottom: 14%;
background: #000;
color: #fe9c26;
font-size: 20px;
font-family: 'Leckerli One', cursive !important;
width: auto;
max-width: 200px;
min-width: 100px;
text-align: center;
padding: 5px;
border-radius: 10px
}
.hide-chat #lhc_container_v2 {
  position: absolute;
  visibility: hidden;
}
* {
  font-family: 'SF Pro Display', sans-serif !important;
  }
  .recharts-text.recharts-label {
    fill: var(--primary-text);
  }
  .was-here .login-btn {
  background: #F25D31 0% 0% no-repeat padding-box;
  margin: 0 1em !important;
  width: 90px;
  min-width: 90px;
  border-radius: 4px;
  color: #fff;
  font-size: 19px;
  margin-left: auto !important;
  -webkit-transition: .3s;
  transition: .3s;
  height: 49px

  }
  .was-here .ion-page {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .was-here .modal-wrapper.sc-ion-modal-md {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100vw;
    min-height: 180px;
    height: auto;
  }
  .was-here-container {
    margin: 0 auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    height: 100%;
    display: flex;
    height: auto;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border: none;
  }
  .was-here-container .cards {
    opacity: 0.5;
    bottom: 0px;
    right: -3em;
  }
  .was-here-container p {
    width: auto;
    font-size: 18px;
    margin: 0px;
    margin-left: 1em;
    margin-right: 1em;
    text-align: center;
    color: #444444;
    /* font-weight: bold; */
    z-index: 99999;
    text-shadow: 1px 1px rgba(255,255,255,1);
  }
  .was-here-container a {
    text-decoration: underline;
    color: #444444;
    font-weight: bold;
  }
  .was-here-container a:hover {
    color: #5A6169;
  }
  .was-here .login-btn:hover {
    color: #000;
  }
ion-router-outlet > .ion-page {
  /* -webkit-animation: fadeIn .9s 1 forwards;
          animation: fadeIn .9s 1 forwards; */
          scroll-behavior: smooth;
}
ion-select-popover ion-list ion-item {
  --border-width: 0;
  --inner-border-width: 0;
}
 ion-radio {
  --color: transparent;
  --color-checked: transparent;
}
@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 0vh, 0);
            transform: translate3d(0, 0vh, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeIn {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 0vh, 0);
            transform: translate3d(0, 0vh, 0);
  }
  100% {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
            transform: translate3d(0, 0, 0);
  }
}
::-moz-selection {
background: #F2F2F2; /* Safari */
}
::selection {
background: #F2F2F2; /* Safari */
}
::-moz-selection {
background: #F2F2F2; /* Firefox */
}
html body {
  /* max-width: 1920px; */
  margin: 0 auto;
  position: relative;
}
.sc-ion-modal-md-h:first-of-type {
    --backdrop-opacity: var(--ion-backdrop-opacity) !important;
}
.toolbar-container {
    padding-bottom: 10px;
}
.header-md {
  border-bottom: 1px solid #707070;
  z-index: 999999999;
}
.loading-hidden .loading-wrapper {
  background: none;
  background-image: url("./images/Logo_bingo_bet_green.png");
  background-size: contain;
  background-repeat: no-repeat;
  bottom: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  width: 300px;
}
.loading-hidden .spinner-dots.sc-ion-loading-md {
color: #C23826;
position: relative;
    top: 45px;
}
.centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.loading-hidden .backdrop-no-tappable{
  /* background: var(--ion-background-color); */
  background: var(--ion-background-color)
}
.loading-hidden{
  --backdrop-opacity: 1;
  color: green;
}
.card {
 min-width: 200px;
 display: -webkit-box;
 display: -ms-flexbox;
 display: flex;
 margin: 0 !important;
}
.nomargin {
  margin: 0px;
}
.row .cardgame {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 40%;
          flex: 1 1 40%;
  margin: 10px 5px;
  max-width: 24%;
}
.games-container {
  padding-top: 3em !important;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;

}
.capitalize {
  text-transform: capitalize;
}
.card-small {
    -webkit-box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12), 0 1px 10px rgba(90, 97, 105, 0.06), 0 7px 31px rgba(90, 97, 105, 0.1) !important;
            box-shadow: 0 2px 0 rgba(90, 97, 105, 0.11), 0 4px 8px rgba(90, 97, 105, 0.12), 0 1px 10px rgba(90, 97, 105, 0.06), 0 7px 31px rgba(90, 97, 105, 0.1) !important;
}
.margin-top1em {
  margin-top: .5em
}
.margin-top3em {
  margin-top: 3em;
}
.border-bottom .btn {
  margin: 5px;
}
.flex-row {
  width: 100%;
  max-width: 300px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.user-avatar-small {
  max-width: 30px;
}

.margintopbottom {
  margin: 1em 0px;
}
.menu-content-open {
  pointer-events: all ;
}
.sidebar {
  z-index: 999999999;
}
#sc-launcher{
  position: absolute;
  z-index: 99999;
  border: none;
  display: block;
  width: 1px;
  height: 1px;
}
@media screen and (min-width: 992px) {
  .only-mobile {
    display: none;
  }
  .only-desktop {
    display: block;
  }
  .userActions {
    margin-right: 2em !important;
  }
  .row .cardgame {
    max-width: 24%;
  }
}
@media screen and (max-width: 992px) {
  .only-mobile {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
  }
  .only-desktop {
    /* display: none !important; */
  }
}
@media screen and (max-width: 500px) {
  .toolbar-container {
    height: 56px;
    padding: 0px;
    position: fixed;
  }
  .row .cardgame {
    -webkit-box-flex: 1;
        -ms-flex: 1 1 45%;
            flex: 1 1 45%;
    padding: 0;
    max-width: 100%;
    margin: 5px;
  }
  #sc-launcher .sc-chat-window.opened {
    right: 0px;
    bottom: 0px;
  }
  ion-content .account-details-container .lower{
  width: 100%;
  }
}
