
.country .hide {
  display: none !important;
}
.logged .userpic {
  cursor: pointer;
}
.logged .userpic:hover, .deposit-money-button:hover{
  box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
  transition: all .3s ease-out;
}
#header-menu {
  margin-top: 10px;
  flex: 0 1 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-left: 1.2em;
}
#header-menu .header-menu-top a.other-games {
  max-width: 80px;
}
.header-menu-top {
  display: flex;
  width: 95%;
  justify-content: space-between;
  margin-top: -.3em;
    height: 10.5em;
}
.header-menu-top p {
  margin-top: -.4em;
}
#header-menu .header-menu-top a {
  text-transform: uppercase;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  font-size: calc(18px + 6 * ((100vw - 1920px) / 680));
  text-decoration: none;
  text-align: left;
  flex: 0 1 22%;
  max-width: 160px;
  cursor: pointer;
  transition: .3s;

  white-space: nowrap;
}
#header-menu .header-menu-top a p {
    color: #F25D31;
    /* font-family: "SF Pro" !important; */
    font-family: 'Leckerli One', cursive !important;
    font-weight: normal;
    text-shadow: 0 1px rgba(0,0,0,.3);
    /* font-weight: 700; */
}
#header-menu .header-menu-top .bets-red p{
  color: #ff4362;
  /* font-family: "SF Pro" !important;*/
  font-family: 'Leckerli One', cursive !important;
}
#header-menu .header-menu-top a:hover p {
  transition: .3s;
}
.header-menu-top .cur {
  color: #444444;
}
.hvr-float-shadow:hover:before{
    opacity: 1;
}
.hvr-float-shadow:before {
    pointer-events: none;
    position: absolute;
    z-index: 1;
    content: '';
    top: 80%;
    height: 10px;
    width: 80%;
    opacity: 0;
    background: radial-gradient(ellipse at center, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0) 80%);
    transition-duration: 0.3s;
    transition-property: transform, opacity;
  }
  #header-menu .header-menu-top a:active:before {
    background: none;
  }

.videoSlots .box {
  background-image: url("../../images/videoslots(2).png");
      height: 110px;
      width: 100px;
      margin-top: 1em
}
.other-games .box {
  background-image: url("../../images/menu/other.png");
      height: 86px;
          margin-bottom: 6px;
}
.lotto .box {
  background-image: url("../../images/menu/lotto.png");
  height: 70px;
  margin-bottom: 10px;
}
.tableGames .box {
  background-image: url("../../images/menu/games.png");
  height: 100px;
}
.liveCasino .box {
  background-image: url("../../images/menu/live.png");
}
.bets-red .box {
  background-image: url("../../images/bets(1).png");
  height: 75px;
  margin-bottom: 10px;
}
.box {
  height: 100px;
  width: 90px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}
#header-menu .header-menu-top .lotto,
#header-menu .header-menu-top  .bets-red,
#header-menu .header-menu-top  .liveCasino,
#header-menu .header-menu-top  .videoSlots,
#header-menu .header-menu-top  .tableGames
 {
  max-width: 80px;
  display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
}

.header-menu-bottom {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 58px;
  width: 100%;
      padding-right: 20px;
}
.header-menu-bottom .deposit-money-button {
  background: #F25D31 0% 0% no-repeat padding-box;
  width: 84px;
  height: 38px;
  border-radius: 4px;
  color: #fff;
  font-size: 20px;
  /* border: 1px solid #444444; */
  margin-left: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  justify-content: center;
}
.header-menu-bottom p {
  color: #444444;
  font-size: 20px;
  margin: 0;
}
.coins {
  width: 4.3em;
  margin-right: 10px;
  height: 64.5px;
  background: url("../../images/money.svg");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
.header-menu-bottom .header-balance {
  padding-left: 3px;
  padding-right: 0px;
  margin-right: -32px ;
}
.header-menu-bottom .in-game-balance {margin-right: 1em}
.header-menu-bottom ion-select {
  width: 50px;
  border: none;
  font-size: 20px;
  color: #444444;
  --placeholder-color: var(--primary-text);
  --placeholder-opacity: 1;
  margin-right: 10px;
  display: flex;
  --padding-end: 0px;
      padding-left: 0px;
}
.header-menu-bottom ion-select .select-icon-inner{
  border-top: 5px solid red !important;
}
.item-interactive-disabled.sc-ion-label-md-h:not(.item-multiple-inputs), .item-interactive-disabled:not(.item-multiple-inputs) .sc-ion-label-md-h {
  opacity: 1 !important;

}
@media screen and (min-width: 1919px) {
  #header-menu .header-menu-top a {
    font-size: 18px;
  }
  #header-menu .header-menu-top .tableGames {
    max-width: 90px;
  }
}
@media screen and (max-width: 1610px) {
  #header-menu .header-menu-top a {
    font-size: 18px;
  }
}
@media screen and (max-width: 1400px) {
  .logo-darkmode-container .top-container {
      padding-left: 2%;
    }
  #header-menu .header-menu-top a {
    font-size: 18px;
  }
@media screen and (max-width: 1366px) {
  #header-menu .header-menu-top a {
    font-size: 18px;
  }
  .logo-darkmode-container .top-container {
      padding-left: 4%;
    }
  #header-menu {
    margin-left: 0px;
    margin-right: 1.2em;
  }
  .logged p, .logged a {
    margin: 0;
    font-size: 16px;
  }
  .header-menu-bottom ion-select, .header-menu-bottom p,.header-menu-bottom .deposit-money-button  {
    font-size: 16px;
  }
  .header-menu-bottom {
    height: 50px;
}
}
@media screen and (max-width: 1280px) {
  .logo-darkmode-container .top-container {
      padding-left: 5%;
    }
  #header-menu {
    margin-left: 0px;
  }
  .lang-select {
    margin-left: auto;
    max-width: 80%;
  }
  #header-menu .header-menu-top a {
    font-size: calc(18px + 6 * ((100vw - 1024px) / 680));
  }
}
@media screen and (max-width: 1200px) {
  #header-menu .header-menu-top a {
    font-size: 18px;
  }
}
@media screen and (max-width: 1025px) {
  .lang-select {
    margin-left: auto;
    max-width: 60%;
    width: 55%;
  }
  .header-menu-top {
    margin-top: 0em;
  }
  .help-btn {
    width: 75px;
  }
  .logged .userpic {
    width: 50px;
    height: 50px;
    min-width: 50px;
    cursor: pointer;
  }
  .login-register-container {
    /* margin-top: 10px; */
  }
  #header-menu {
    margin: 0px;
  }
  #header-menu .header-menu-top a {
    font-size: 18px;
  }
  #header-menu .header-menu-top a.other-games,
  #header-menu .header-menu-top .bets-red {
    max-width: 18%;
    margin-left: -15px;
  }
  .header-menu-bottom {
    height: 90px;
    margin-top: -12px !important;
}
.logo-darkmode-container .top-container {
    margin-top: 35px;
    padding-left: 1.5em;
  }
  .header-menu-bottom .deposit-money-button {
    height: 34px;
  }
  .logo ion-item {
    --min-height: 95%;
  }
  .unlogged .login-btn, .unlogged .register-btn {
    margin-left: 0px;
}
}
}
