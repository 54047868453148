.user-container {
  display: flex;
}
.transfer-submit {
  margin-top: 4em !important;
}
.depst {
  width: 90px !important;
  margin-left: 0px !important;
}
.large {
  width: 60% !important;
  max-width: 480px;
  color: #F25D31 !important;
  font-weight: bold;
}
.mrgbtn {
  margin-bottom: 5px !important
}
.spinner-box {
  position: absolute;
  z-index: 999999;
}
.user-cabinet-container {
  position: relative;
}
.transactions .card-body {
  min-height: -webkit-fit-content;
  min-height: -moz-fit-content;
  min-height: fit-content;
}
.graphs .pie {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.graphs {
  width: 100%;
  max-width: 600px;
}
.graphs .pie .recharts-surface {
  width: 280px !important;
  height: 280px !important;
}
.graphs .pie tspan {
  font-size: large;
    width: auto;
    max-width: 180px;
}
.graphs .pie .recharts-wrapper {
  margin-bottom: 10px;
  margin-top: 10px;
  width: auto !important;
  height: auto !important;
}
.pie p {
  font-family: 'SF Pro Display', sans-serif !important;
  margin-bottom: 10px;
  font-size: 20px;
  color: var(--primary-text);
  margin: 0;
}
.loan {
  width: auto;
  max-width: 135px;
}
.card-paginator {
  display: flex;
  width: auto;
  margin-top: 2rem;
  margin-bottom: 5rem;
  /* justify-content: flex-end; */
}
.time-buttons {
  display: flex;
  width: auto;
  max-width: 100%;
  flex-wrap: wrap;
  justify-content: space-between;
}
.time-buttons ion-item{
  margin-right: 10px;
  margin-top: .5em;
  margin-bottom: .5em;
}
.user-cabinet-container .card {
  width: 100%;
  max-width: 600px;
  height: auto;
  /* max-height: 600px; */
  border: none;
  box-shadow: none !important;
  margin-top: 3em !important;
  z-index: 1 !important;
  background: transparent
}
.user-cabinet-container .charts-container {
  display: flex;
  flex-direction: column;
  margin-top: 4em;
}
.user-cabinet-container .pies-container {
  display: flex;
  width: 100%
}
.user-cabinet-container .chart-bars {
    margin-top: 4em;
    margin-bottom: 3em;
}
.user-cabinet-container .chart-bars {
  width: 90% !important;
}

.transactions .card-table.card {
  margin-bottom: 1em !important;
}
.card .view-report a:hover {
  color: #fff;
}
.card .view-report a {
    color: #fff;
    font-size: 20px;
}
.button-to {
  background: #F25D31 0% 0% no-repeat padding-box;
    width: auto;
    height: 38px;
    border-radius: 4px;
    color: #fff;
    font-size: 20px;
    width: 130px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: auto;
}
.button-to:hover{
  box-shadow: 5px 5px 10px rgba(0,0,0,0.5);
    transition: all .3s ease-out;
}
.charts-container select {
  height: 38px !important;
  font-size: 16px;
}
/* .charts-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
} */
.card-paginator div {
  font-size:20px;
  color: var(--primary-text);
  border: 1px solid;
  border-color: var(--primary-text);
  width: 46px;
  height: 44px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-right: 10px;
}
.paginator-container div {
  flex-shrink: 0;
}
.card-paginator .paginator-container {
  display: flex;
  width: auto;
  justify-content: flex-start;
  overflow-x: scroll;
  max-width: 550px;
  height: 64px;
  align-items: flex-start;
  border: none;
  padding-bottom: 10px;

}
.card-paginator .paginator-container::-webkit-scrollbar-track
{
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
	/* border-radius: 10px; */
	background-color: none;
}

.card-paginator .paginator-container::-webkit-scrollbar
{
	height: 10px;
	background-color: none;
}

.card-paginator .paginator-container::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-color: var(--primary-text);
}
.paginator-container div:hover{
  box-shadow: 0px 3px 4px rgba(0,0,0,0.5);
}
.change-password {
  min-width: 185px !important;
}
.games-limit .custom-block {
  max-width: 425px;
}
.avatar-prev {
  width: 110px;
  height: 110px;
  background-size: cover !important;
  background-position: center !important;
  background-repeat: no-repeat !important;
}
.account-details-container .lower {
  margin-top: 2em;
  margin-bottom: 2em;
}
.user-cabinet-container {
  margin-top: 35px;
  width: 100%;
}
.app-centered {
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
}
.userpic-container {
  overflow: hidden;
  border: 1px solid #444444;
  cursor: pointer;
}
.wrong {
  border: 1px solid #C11916;
}
/*deposit-money*/
.deposit-money,
.transfer-money,
.withdraw-money,
.funds-security,
.transactions {
  position: relative;
  margin-left: 10%;
}
.InputFromTo-to input, .DayPickerInput input {
  border-color: var(--primary-text) !important;
}
.shade {
  position: absolute;
  right: 2em;
  z-index: 1;
}
.win-bold {
  font-weight: bold;
}
.shade .top-shade {
  display: flex;
  font-size: 148px;
  color: #F2F2F2;
  height: auto;
  margin: 0;
  margin-left: -.5em;
  -webkit-touch-callout: none;
 -webkit-user-select: none;
 -moz-user-select: none;
 -ms-user-select: none;
 user-select: none;
 z-index: 1;
}
.shade .bottom-shade {
  font-size: 64px;
  color: #F2F2F2;
  margin: 0;
  margin-top: -.8em;
  height: auto;
  -webkit-touch-callout: none;
 -webkit-user-select: none;
 -moz-user-select: none;
 -ms-user-select: none;
 user-select: none;
 z-index: 1;
}
.title span {
  font-size: 48px;
  color: var(--primary-text);
}
.title img {
  width: 40px;
  height: 40px;
  margin-right: 5px;
  align-self: center;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}
.title-2 img {
  width: 40px;
  height: 40px;
  align-self: center;
  margin-right: 5px;
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}
.shade img {
  width: 80px;
  height: 80px;
  margin-right: 20px;
  align-self: center;
  -webkit-filter: grayscale(100%) opacity(20%);
          filter: grayscale(100%) opacity(20%);
}
body.dark .shade img {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
}
.shade .deposit-icon, .shade .balance-icon {
  width: 100px;
  height: 100px;
}
.title {
  display: flex;
  font-size: 48px;
  margin-bottom: 0em;
  z-index: 2;
  color: var(--primary-text);
  position: relative;
}
.deposit {
  position: relative;
  font-size: 24px;
  color: var(--primary-text);
  margin: 0;
  margin-top: 10px;
  z-index: 2;
  max-width: 350px;
  position: relative;
}
.deposit span{
  margin-left: auto;
  position: absolute;
  right: 0;
}
.transfer-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 350px;
  margin-top: 60px;
}
.transfer-from {
  margin: 0;
  font-size: 20px;
  color: var(--primary-text);
}
.deposit b {
  font-weight: bold;
}
.closure .deposit {
  max-width: none;
}
.deposit-field {
  width: 154px;
  max-width: 154px;
}
.total-amount {
 position: absolute;
  margin-top:  1em;
}
.submit {
  margin-top: 1em;
  margin-left: 14.2em;
}
/*deposit-money-end*/
/*funds-security*/
.funds-security {
  margin-left: 59px;

}
.funds-security-container {
  max-width: 750px;
  width: 70%;
}
.funds-security-container p {
  z-index: 3;
  position: relative;
}
.funds-security-container .mastercard {
  background: url('../../images/logos/2615546914.png');
  width: 139px;
  height: 42px;
}
.funds-security-container .visa {
  background: url('../../images/logos/500073438.png');
  width: 76px;
  height: 42px;
  margin-left: 20px;
}
.funds-security-container .mastercard, .funds-security-container .visa {
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  display: inline-block;
}
/*funds-security-end*/
/*transactions*/
.transactions-container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
}
.transactions .submit {
  margin-left: 23vw;
  margin-top: 5em;
}
.card-table {
  width: 80%;
  margin-top: 3em !important;
}
.card {
  background: var(--ion-backdrop-color);
  box-shadow: none;
}
.table thead th {
  font-weight: normal;
  color: var(--primary-text);
  border-bottom: 1px solid #707070 !important;
}
.table {
  font-weight: normal;
  color: var(--primary-text) !important;
}
tbody td {
  border: none !important;
}
/*transactions-end*/
/*account-details*/
.account-details-container {
  width: 80%;
  margin-left: 6em;
  margin-bottom: 4em;
}
.account-details-container .step-input-container{
  display: flex;
  justify-content: flex-start;
  width: 90%;
  align-items: center;
}
.account-details-container .step-input-title {
  width: 20%;
  margin: 0px;
}
.account-details-container .header-search {
  max-width: 300px;
  background: var(--ion-semitransparent);
}
.account-details-container .gender,
.account-details-container .date{
  width: 80px !important;
}
.account-details-container .lan,
.account-details-container .cur{
  width: 150px !important;
}
.step-input-title {
  color: var(--primary-text);
}
.account-details-container .flag-select__btn .flag-select__option__label {
  display: flex;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  max-width: 100%;
}
.account-details-container .subtitle-alert {
  color: #C11916;
  font-size: 14px;
  text-align: left;
  margin: -16.9px 0px;
  margin-top: -20.5px;
  margin-left: 25%;
}
.account-details-container .next-step {
  color: #C11916;
  font-size:34px;
  margin:0 auto;
  text-align: right;
  cursor: pointer;
  width: 100px;
  z-index: 9999;
}
.account-details-container .avatar {
  margin-top: 3em;
}
.account-details-container .userpic {
  width: 114px;
  border-radius: 50%;
  height: 114px;
}
.account-details-container .submit, .account-details-container .next-step {
  margin: 0;
}
.account-details-container .submit {
  margin-right:20%;
  width: auto;
  margin-left: .1vw;
  font-size: 20px
}
.account-details-container .submit ion-label{
  padding: 10px;
  margin-left: .1vw;
}
.lower {
  width: 50%;
  margin-top: 3em;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.title-2 {
  display: flex;
  font-size: 30px;
  margin-bottom: 0em;
  z-index: 2;
  margin-top: 1em;
  margin-left: 1.5em;
  color: var(--primary-text);
  position: relative;
}
.account-details-container .mobilecode {
  max-width: 100px;
  margin-right: 1em;
}
.account-details-container .mobile {
  max-width: 12.4em;
}
.account-details-container .games-limit .custom-block{
  max-width: 430px;
}
/*account-details-end*/
/*multiple-logins*/
.multiple-logins {
  margin-left: 0;
  margin-top: 9em;
  /* border-top: 1px solid #707070;
  border-bottom: 1px solid #707070; */
  margin-bottom: 5em;
}
.account-details-container td .submit {
  margin: 0
}
.logs-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 140px;
}
.logs-container .data{
  height: 50%;
  width: 30%;
  display: flex;
  max-width: 330px;
  flex-direction: column;
  justify-content: space-between;
}
.logs-container p {
  margin: 0;
  z-index: 3;
  position: relative;
  color: var(--primary-text)
}
/*multiple-logins-end*/
/*account-closure*/
.closure {
  margin-top: 6em;
  margin-left: 0;
}
.closure p {
  margin: 0;
  color: var(--primary-text);
  z-index: 3;
  position: relative;
}
.closure .alert-text {
  color: #C11916;
  margin-top: 2em;
}
.closure-btn {
  margin-top: 2em;
  margin-left: .1vw;
  width: 161px
}
.step-input-container ion-input.money-value{
  max-width: 150px;
  --padding-start: 10px;
}
.step-input-container ion-input.money-value input{
  padding-left: 17px;
  padding-right: 13px;
}
/*account-closure-end*/
/*verify-account*/
.thumb {
  display: flex;
  overflow: hidden;
  width: 100% !important;
  height: 100% !important;
  box-sizing: border-box;
  border-radius: 4px;
};
.peviewImg {
  display: block;
  width: 100%;
  height: 100%;
}
.file-zone, .file-zone-active {
  border: 1px solid #707070;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  width: 100%;
  height: 214px;
}
.dropzone {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  z-index: 4;
}
.file-zone-img {
  background: url('../../images/upload.svg');
  width: 76px;
  height: 57px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}
.file-zone-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width:366px;
}
.verify {
  margin-top: 2em;
  margin-left: 3em;
}
.verify .step-input-container, .verify .previews-container {
  margin-left: 1em;
  margin-bottom: 2em;
}
.verify p{
  margin: 0;
  color: var(--primary-text);
  font-size: 20px;
  position: relative;
  z-index: 4;
}
.verify .title-2 {
  font-size: 26px;
  margin-bottom: 0em;
  z-index: 2;
  margin-top: 1em;
  color: var(--primary-text);
  position: relative;
}
.verify .doc {
  max-width: 170px;
}
.previews-container {
  display: flex;
  width: 70%;
  justify-content: space-between;
}
.deposit-money .verify .next-step {
  margin-left: 63% !important;
  margin-top: 1em;
}
.verify .proof {
  max-width: 770px;
  margin-left: .7em !important;
  margin-top: 1em;
  margin-bottom: 1em;
}
.verify .proof span {
  font-weight: bold;
}
.previews-container-2 {
  margin-top: 2em;
}
.deposit-money .verify  .last-one {
  margin-bottom: 1em;
}
.terms {
  margin-bottom: 3.5em;
}
/*verify-account-end*/
@media screen and (max-width: 1366px) {
  .deposit-money, .transfer-money, .withdraw-money, .funds-security, .transactions {
    margin-left: 5%;
  }
  .file-zone-container {
    width: 48%;
  }
  .title, .title span{
    font-size: 42px;
  }
}
@media screen and (max-width: 1366px) {
  .details .dates {
    max-width: 80%;
  }
}
