/*
* Prefixed by https://autoprefixer.github.io
* PostCSS: v7.0.29,
* Autoprefixer: v9.7.6
* Browsers: last 4 version
*/
/* border: 1px solid #bdbbbb; */
ion-list.sc-ion-select-popover-md{
  border: .5px solid #bdbbbb !important;
  border-radius: 4px;
  overflow: hidden !important;
}
ion-item {
  border-radius: 4px;
}
.regg .jackpot-title {
  flex-direction: row;
  align-items: center;
}
.regg .banner-info {
  align-items: center;
  max-height: 135px;
  margin-bottom: 1em;
}
.regg .jackpot-title {
  min-height: auto;
height: auto;
}
body .regg  .jackpot-roll {
  margin-bottom: -1em;
}

.flag-select__options {
  border-radius: 4px;
}
.regg .jackpot-top {
  border-bottom: none;
}
.register .modal-container-title{
  border: none;
  background: transparent;
  /* margin: 0 auto; */
  padding-right: 0%;
  position: relative;
  margin-top: .3em;
  margin-left: -13%;
  font-size: 250px;
  width: auto;
  justify-content: flex-end;
  color: #F2F2F2;
  font-family: "SF Pro Medium" !important;
  font-weight: normal;
  opacity: var(--ion-reg-opacity);
}
.register .modal-container-title-second {
  position: absolute;
  font-family: "SF Pro Medium" !important;
  top: 20px;
  z-index: 3;
  color: #F2F2F2;
    left: 0;
      top: 70px;
  font-size: 250px;
}
.flag-select__btn:after {
    opacity: .3;
}
.register ion-input, .register ion-select {
  --placeholder-color: var(--ion-placeholder-default);
  --placeholder-opacity: 1;
  color: var(--ion-register-default);
  opacity: 1 !important;
  background: var(--ion-semitransparent);
  z-index: 999999999;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 8px;
}
.flag-select__btn {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
}
.flag-select__btn > .flag-select__option {
  padding: 0px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.flag-select {
  padding-bottom: 0px !important;
}
.flag-select__options  {
  background: var(--ion-background-color2) !important;
}
.register .native-input.sc-ion-input-md {
  border-color: var(--ion-register-default);
  z-index: 999999999;
}
.register .modal-container {
  border: none;
  width: 100%;
  max-width: 1011px;
  height: auto;
  margin-left: -7vw;
  min-height: 508px;
  margin-top: .9em;
  z-index: 3;
}
.step {
  height: auto;
  overflow: hidden;
}
.register .step-title {
  font-size: 34px;
  color: var(--ion-register-default);
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  cursor: pointer
}
.flag-select__option__label {
  color: var(--ion-register-default) !important;
}
.step .header-search{
  flex: 1 1 472px;
}
.step-input-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  height: 50px;
  margin: 16.5px 0;
}
.z-index-1 {
  z-index: 1 !important;
}
.register .step-input-title {
  font-size: 20px;
  color: var(--ion-register-default);
  margin:0px;
  width: 220px;
}
.register {
  position: relative;
}
.step {
  width: 100%;
  max-width: 700px;
}
.step .header-search, .date-of-birth-container{
  max-width: 472px;
}
/* .first-step-hideable, .second-step-hideable,.third-step-hideable {
  height: auto;
  transition: .3s;
} */
.register .next-step {
  color: #C11916;
  font-size:34px;
  margin-left: auto;
  text-align: right;
  cursor: pointer;
  width: 100px;
  z-index: 9999;
}
.register .gender {
  max-width:97px;
}
.date-of-birth-container {
  display: flex;
  justify-content: space-between;
}
.YearNavigation {
  z-index: 99999;
}
.dates {
  max-width: 49%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
}
.register .date {
  margin: 0 auto;
  max-width: 97px;
  flex: 0 0 97px;
}
.dates .header-search {
  margin-left: 0px;
  margin-right: 30px;
}
.hide {
  display: none !important;
}
.register .country {
  max-width: 200px;
}
.country .flag-select__option__label {
  text-align: left;
}
.step-input-container .flag-select__options  {
  max-height: 350px !important;
    top: -248px !important;
    z-index: 999999999;
    background: var(--ion-background-color2);
}
.flag-select__option__icon {
    top: 0 !important;
}
.flag-select__option.has-label {
    padding: 10px 20px 10px 8px !important;
}
.flag-select button:focus {
    outline: none !important;
}
.flag-select__options {
  width: 20em !important;
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2), 0 8px 10px 1px rgba(0, 0, 0, 0.14), 0 3px 14px 2px rgba(0, 0, 0, 0.12);
}
.flag-select__option__label {
  font-family: "SF Pro Display" !important;
  font-size: 16px !important;
  width: 100%;
  max-width: 137px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.select-icon {
  color: red !important
}
.register .mobilecode {
  max-width: 100px;
}
.register .mobile {
  max-width: 49%;
  margin-left: auto;
  margin-right: 8px;
}
.register hr {
  border-top: 1px solid var(--ion-register-default);
  margin-top: 2em;
  width: 97%;
}
.register .subtitle{
  color: var(--ion-register-default);
  font-size: 14px;
  text-align: right;
  margin: -16.9px 0px;
  margin-top: -20.5px;
  /* margin-bottom: -19px; */
  padding-right: 8px;
}
.register .wrong {
  border-color: #C11916 !important;
  --border-color: #C11916 !important;
}
.register .subtitle-alert {
  color: #C11916;
}
.register .comment {
  color: var(--ion-register-default);
  font-size: 19px;
  letter-spacing: 0px;
  margin-top: 2.5em;
  text-align: center;
}
.register .item-inner{
border-style: unset !important;
}
.custom-block {
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 480px;
}
.register .custom-input {
  flex: 1 3 330px;
  /* max-width: 330px; */
}
.register .custom-limit {
  margin-left: 1em
}
.register .register-button {
  width: 155px;
  z-index: 9999;
}
 .register .with-checkbox-p, .register a{
   font-size: 19px;
   padding-left: 2em;
   margin: 0;
   z-index: 9999;
 }
 .register .with-checkbox-p, .register .annotation {
   color: var(--ion-register-check)
 }
 .register a {
   color: var(--ion-register-link);
   font-weight: var(--ion-register-link-weight);
   padding-left: 0em;
   cursor: pointer;
 }
 .with-checkbox {
   display: flex;
   margin: 20px 0px;
   align-items: center;
 }
.register ion-checkbox {
  min-width: 18px !important;
}
.register .first {
  margin-top: 4em;
}
.with-checkbox-p strong {
  font-weight: bold;
  font-family: "SF Pro" !important;
}
.register .annotation, .register .annotation a {
  font-size: 14px;
  z-index: 9999;
}
.register .annotation {
  margin-bottom: 4em;
  margin-left: -11.5em
}
.login-modal.register {
  max-width: 1920px;
  margin: 0px auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer-container {
  margin-top: 0px
}
.unselectable {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none;   /* Chrome/Safari/Opera */    /* Konqueror */
  -moz-user-select: none;      /* Firefox */
  -ms-user-select: none;       /* Internet Explorer/Edge */
  user-select: none;           /* Non-prefixed version, currently*/
}
.register-img {
  position: absolute;
  display: block;
  width: 27vw;
  height: 359px;
  background: var(--ion-register-banner);
  z-index: 1;
  bottom: 1px;
  right: -4%;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: bottom;
}
.first-step-hideable, .second-step-hideable,.third-step-hideable {
  height: auto;
  max-height: 0px;
  transition: max-height 0.15s ease-out;
  overflow: hidden;
}

/* .second-step-hideable, {
  height: auto;
} */
a:not([href]):hover {
    color: #C11916;
    text-decoration: underline;
    transition: .3s
}
.select-icon {
  opacity: 1 !important;
}
.open.second-step-hideable, .open.third-step-hideable, .open.first-step-hideable {
  max-height: 3000px;
  transition: max-height .7s ease-in;
}
.closed.second-step-hideable, .closed.third-step-hideable, .closed.first-step-hideable {
  max-height: 0px;
  transition: max-height .7s ease-in;
}
