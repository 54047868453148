@media screen and (min-width: 1919px) {
  .slick-track {
    /* left: -3px !important; */
  }
  .slick-list {
        max-width: 1343px !important;
  }
}

@media screen and (max-width: 1700px){
  ion-content .user-cabinet-container .chart-bars{
    width: 100% !important;
  }
}
@media screen and (max-width: 1600px){
  h1.favs {
    font-size: 40px;
  }
  .jackpot-title {
    max-width: 76%;
  }
}
@media screen and (max-width: 1380px){
  .jackpot-roll.table-pic {
    height: 300px !important;
  }
}
@media screen and (max-width: 1680px){
  .bonuses-container .bonus span {
    font-size: 18px !important;
    line-height: 10px;
  }
}
@media screen and (max-width: 767px) {
  ion-content .game .game-preview {
    height: 110px !important;
  }
  ion-content .game.doubled {
    height: -webkit-fit-content !important;
    height: -moz-fit-content !important;
    height: fit-content !important;
    max-height: unset
  }
  ion-content .slick-slider .game .game-preview {
    height: 150px !important;
  }
  ion-content .slick-slider {
    width: 90%;
    margin: 0 auto;
  }
  ion-content .favorites-container .slick-prev {
    left: -25px !important;
  }
  ion-content .favorites-container .slick-next {
    right: -25px !important;
  }
  .graphs {
    margin: 0 auto;
  }
  .verify .previews-container {
    margin-left: 0 !important;
  }
  .transactions-container .time-buttons {
    max-width: 273px;
  }
  .slick-track .game {
    max-width: 100%;
  }
  #react-root .flag-select__options {
    top: 30px;
  }
  .slick-list .game {
    /* height: 195px !important; */
  }
  .sticky {
    left: 0;
    bottom: 106px;
    width: 33px;
    height: 200px;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-overflow-scrolling: auto;
  }
  .login-modal.logreg .modal-container {
    margin: 0 auto;
    width: 90%;
  }
  .login-modal.logreg .modal-container ion-input{
    width: 90%;
  }
  .form-login {
    margin: 0 auto;
    width: 100%;
  }
  .form-login ion-input{
    margin-left: auto;
    margin-right: auto;
  }
  .form-login .login-btn {
    width: 100%;
  }
  .login-modal p {
    text-align: center;
    width: 90%;
  }
  .sticky div {
    position: sticky;
    left: -47px;
    z-index: 999999;
    margin: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .sticky .sign-up {
    bottom: 150px;
  }
  .sticky .login {
    bottom: 100px;
    margin-bottom: -10px;
  }
  .sticky .help-sticky {
    display: none
  }
}
@media screen and (min-width: 768px) {

  .sticky {
    left: 0;
    bottom: 156px;
    width: 33px;
    height: 300px;
-ms-flex-pack: distribute;
    justify-content: space-around;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-overflow-scrolling: auto;
  }
  .sticky div {
    position: sticky;
    left: -47px;
    z-index: 999999;
    margin: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .sticky .sign-up {
    bottom: 150px;
  }
  .sticky .login {
    bottom: 100px;
    margin-bottom: -10px;
  }
}
@media screen and (max-width: 1550px) {
  .bonuses-container .bonus {
    -webkit-box-flex: 0;
        -ms-flex: 0 1 28%;
            flex: 0 1 28%;
    min-width: 350px;
  }
  .bonuses-container {
    margin: 0 auto !important;
  }
}
@media screen and (max-width: 1450px) {
  /* #header-menu {
    margin-top: 1em;
  } */
  .header-menu-bottom {
    padding-right: 2em;
  }
  .favorites-container {
    max-width: 1200px !important;
}
  .userActions {
    height: 200px;
    margin-top: 1.1em;
  }
  .video-slots-container, .table-games-container, .live-casino-container {
    width: 93% !important;
  }
}
@media screen and (max-width: 1366px) {
  .favorites-container {
    max-width: 1200px !important;
  }
  .video-slots-container, .table-games-container, .live-casino-container {
    grid-template-columns: repeat(auto-fill, minmax(240px, 1fr)) !important;
  }
  .userActions {
    height: 195px;
  }
  .bonuses-container .bonus span {
    font-size: 18px !important;
  }
}
@media screen and (max-width: 1280px) {
  #header-menu .header-menu-bottom .deposit-money-button {
    margin-left: 25px;
  }
  #header-menu .box {
      width: 80px;
    }
    /* #header-menu .liveCasino .box {
      width: 111px;
    } */
    #header-menu .lotto .box, #header-menu  .other-games .box {
      width: 80px;
    }
    #header-menu .bets-red .box {
      width: 101px;
    }
    .favorites-container {
      max-width: 95% !important;
    }
    .content-container {
      max-width: 590px !important;
    }
    .bonus .info h3, .bonus .info h4 {
      max-width: 310px !important;
    }
    .banner-info {
      height: auto !important;
      max-height: 100% !important;
    }
    .header-search-container {
      max-width: 343px;
      width: 51%;
    }
    #header-menu .header-menu-top a p{
      font-size: 16px;
    }
    .header-search-container {
      max-width: 343px;
      width: 60%;
    }
    .video-slots-container, .table-games-container, .live-casino-container {
    max-width: 1100px;
  }
  ion-content .bonuses-container .bonus {
    margin: 1em;
  }
  ion-content .bonuses-container .bonus h3 {
    font-size: 24px;
  }
  ion-content .bonuses-container .bonus span {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1160px) {

  ion-content .bonuses-container .bonus {
      min-width: 310px;
      margin: .8em;
  }
  .bonuses-container {
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
}
@media screen and (max-width: 900px) {
  .card-body {
    overflow: scroll;
  }
  ion-content .premium-container .card-table {
    width: 93%;
  }
  ion-content .margin-top {
    margin-top: 0px;
  }
  ion-content .live-casino-container .game .main-box,
  ion-content .table-games-container .game .main-box,
  ion-content .video-slots-container .game .main-box {
    /* max-height: 93px !important; */
  }
  ion-content .live-casino-container .game,
  ion-content .table-games-container .game,
  ion-content .video-slots-container .game {
    /* height: 155px !important; */
  }
  ion-content .game.doubled {
    /* height: 250px !important; */
  }
    ion-content .game.doubled .main-box {
    /* max-height: 79% !important; */
  }
  .user-cabinet-container .charts-container  {
    margin-top: 0em;
  }
  .user-cabinet-container .pies-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }

  .user-cabinet-container .charts-container .card-body {
    overflow: visible;
  }
}
@media screen and (max-width: 1024px) {
  .favorites-container {
    max-width: 90% !important;
  }
  ion-content .game .game-preview {
    height: 130px;
  }
  ion-content .slick-slider .game .game-preview {
    height: 130px;
  }
  .slick-slide {
    min-width: 183px ;
  }
  .video-slots-container, .table-games-container, .live-casino-container {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)) !important;
  }
  /* #header-menu {
    margin-top: 1em !important;
  } */
  .bonus .img, .bonus .img2 {
    min-width: 70px !important;
  }
  .bonus .info h3, .bonus .info h4 {
    max-width: 262px !important;
  }
  .content-container {
    max-width: 94% !important;
  }
  .userActions {
    height: 220px;
    margin-top: 0.1em;
  }
  .lang-select {
    max-width: 70% !important;
    width: 72% !important;
  }
  .userActions .logged {
    padding-top: 2.5em;
  }
  .lang-select {
    margin-bottom: 0px;
  }
    #header-menu .lotto .box, #header-menu .other-games .box {
    width: 70px;
  }
    #header-menu .other-games .box {
      height: 70px;
      margin-bottom: 12px;
  }
    #header-menu .bets-red .box {
      width: 65px;
  }
}
@media screen and (max-width: 993px) {
  #react-root .carousel .slider-wrapper.axis-horizontal .slider .slide {
        margin-bottom: 2em;
  }
  .previews-container .file-zone-container {
    width: 49%;
  }
  .previews-container-2 .file-zone-container {
    width: 100%;
  }
  .carousel-root .carousel .control-dots {
    bottom: 30px;
  }
  .slick-list .main-box{
    /* max-height: 140px !important; */
  }
  .slick-slider .game .main-box {
        max-height: unset
        }
  .themeToggle.only-mobile {
    position: absolute;
    right: 5px;top:6px;
  }
  .trnments .bonus .info {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
}
  ion-content ion-header {
    height: 50px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: fixed;
  }
  ion-menu ion-header {
    position: relative;
    height: 50px;
  }
  #providers {
    margin-top: 2em;
  }
  #providers .provider-card {
    width: 200px;
    height: 200px;
  }
  #providers .providers-cards {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
  }
  /* ion-content .banner-container, ion-content .run-game-container {
    margin-top: 50px;
  } */
  .toolbar-contents {
    display: none;
    height: 60px;
  }
  .toolbar-container {
    position: relative;
  }
  .only-mobile.logo {
    position: absolute;
    width: 155px;
    height: 45px;
    /* background: url(/static/media/logo8-22.de564a94.png); */
    background: url('../../images/Logo_bingo_bet_green.png');
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    top: 0;
    left: calc(50% - 77.5px);
    /* top: calc(50% - 22.5px); */
  }
  .only-mobile.logo ion-item {
    width: 100%;
  }
  ion-modal .modal-wrapper{
    width: 90%;
  }
  .logout .modal-container{
    height: 310px
  }
  .mobile-view .lang-select{
    margin-left: 0;
  }
  .mobile-view .unlogged{
    margin-top: 0px;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }
  .mobile-view .login-modal .incorrect-password {
    text-align: center;
  }
  .mobile-view .deposit-money-button{
    margin: 0;
    margin-left: auto;
  }
  .mobile-view .header-menu-bottom {
    height: 45px;
    margin-top: 0px !important;
    padding: 0;
    width: 90%;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    margin: 0 auto;
  }
  .mobile-view .header-balance {
    margin: 0 !important;
  }
  .mobile-view .header-balance ion-select {
    margin-left: -18px;
    margin-right: 0
  }
  .mobile-view .header-menu-bottom .deposit-money-button {
    margin-left: auto;
  }
  ion-popover  {
    z-index: 999999 !important;
  }
  ion-popover ion-item {
    z-index: 999999 !important;
  }
  .jackpot-roll {
    display: none;
  }
  ion-content .jackpot-top {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .jackpot-title {
    width: 100% !important;
    max-width: 100%;
    margin: 0 auto !important;
  }
  .jackpot-title .jackpot-playnow {
    margin-left: 10px;
}
  .jackpot-links {
    width: 100% !important;
  }
  .jackpot-links a p, .jackpot-links span p {
    font-size: 13px !important;
  }
  .jackpot-text {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
    width: 90%;
    margin: 0 auto;
    -ms-flex-pack: distribute;
        justify-content: space-around;
  }
  .jackpot-text p {
    font-size: 18px !important;
  }
  .favorites-container .type {
    font-size: 30px !important;
  }
  .favorites-container .type-description {
    font-size: 14px !important;
  }
  .favorites-container .nofavs {
    margin: 2em auto;
    width: -moz-fit-content;
    width: 80%;
    text-align: center;
  }

  ion-content .favorites-container {
    margin-bottom: -1em;
  }
  .favorites-container .selector ion-toggle {
        margin-right: -35px;
        margin-bottom: 1em;
  }
  .favorites-container .slick-list {
    /* width:70%; */
    /* max-width:270px; */
        height: auto;
    padding: 0 !important
  }
  ion-content .favorites-container .slick-slide {
    /* min-width: 270px; */
    margin-left: 0px;
    margin-right: 0px;
        overflow: hidden;
  }
  ion-content .slick-list .game {
    /* max-width: 100% !important; */
    margin: 0px;
  }
  ion-content .favorites-container .slick-slider .slick-track {
    /* left: 0px !important; */
  }
  ion-content .jackpot-title .jackpot-playnow {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .video-slots-container, .table-games-container, .live-casino-container {
    grid-template-columns: repeat(auto-fill, minmax(120px, 1fr)) !important;
  }
  ion-content .game.doubled {
    height: 250px;
  }
  ion-content .game.doubled .main-box {
    /* max-height: 190px; */
  }
  ion-content .latest-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: auto;
  }
  ion-content .latest-container > div {
    width: 100%;
  }
  ion-content .latest-container h3, ion-content .latest-container h4 {
    margin-top: 1em;
  }
  ion-content .latest-container .winners {
    width: 90%;
  }
  ion-content .latest-container .line {
    height: 1px;
    min-height: 1px !important;
    width: 90%;
    border-top: 1px solid;
    border-color: var(--ion-typee);
    margin-bottom: 0;
  }
  ion-content .winners-container {
    margin-bottom: 1em;
  }
  ion-content #video-slots, ion-content #live-casino, ion-content #table-games, ion-content #lotto, ion-content #other {

     grid-area: wide;
  }
  .video-slots-container, .table-games-container, .live-casino-container, .bets-container {
    grid-template-areas:
      "wide wide"
      ". . "
      ". . ";
  }
  ion-content #video-slots,ion-content .add-games, ion-content #table-games, ion-content #live-casino, ion-content #other, ion-content #lotto {
    width: 100%;
    max-width: 100%;
    margin: 0px;
  }
  ion-content .jackpot-container {
    min-height: 0px;
  }
  ion-content .latest-container h4 {
    font-size: 32px;
  }
  ion-content .bonuses .bonus {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  ion-content .bonus .img, ion-content .bonus .img2 {
    width: 150px;
    height: 150px;
    margin: 0 auto;
  }
  ion-content .bonus .info {
    margin: 0 auto !important;
  }
  ion-content .bonus .info h3, ion-content .bonus .info h4 {
    text-align: center;
    margin: 0 auto;
    width: 100%;
  }
  ion-content .trnments .bonus div {
    margin-bottom: 10px;
  }
  ion-content .trnments .bonus img {
    width: 60% !important;
    max-width: 400px;
  }
  ion-content .winners-container .bonus img {
    width: 80% !important;
    max-width: 400px;
  }
  ion-content .winners-container .bonus p {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  ion-content .bonuses-container .bonus ul {
    padding-left: 1.5em;
  }
  ion-content .bonuses .play-now {
    margin: 10px auto;
  }
  ion-content .links-top div {
    -webkit-box-flex: 0 !important;
        -ms-flex: 0 1 21% !important;
            flex: 0 1 21% !important;
  }
  ion-content .links-top {
    padding: 0em 2em;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    height: auto;
    margin-top: 10px !important;
  }
  ion-content .video-slots-container {
    padding-top: 0;
  }
  ion-content .licensing h3 {
    text-align: center;
  }
  ion-content .licensing p {
    text-align: center;
    font-size: 16px;
  }
  ion-content .links-bottom {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    height: auto;
    padding: 0em;
  }
  ion-content .links-bottom div {
    -webkit-box-flex: 0 !important;
        -ms-flex: 0 1 21% !important;
            flex: 0 1 21% !important;
  }
  ion-content .logo-ridotto {
    position: inherit;
  }
  ion-content .bottom .container {
    min-width: 84%;
    width: 100%;
    margin: 0;
    padding: 0;
  }
  ion-content .bottom {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;

  }
  ion-content .bottom .container a {
    font-size: 16px;
  }
  ion-content .bottom .container div {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-top: 10px;
  }
  ion-content .main-box .topleft {
    /* width: 85%; */
    /* max-width: 103px; */
    /* height: 30px; */
    /* background-size: contain; */
  }
  ion-content .main-box .topleft .provider-logo {
    margin-top: -5px;
    max-height: auto;
  }
  ion-content .bottom .imgs {
    position: relative;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    left: inherit;
    margin-bottom: 10px;
  }
  ion-content .bottom .logo-ridotto,
  ion-content .bottom .eighteen {
    height: 60px;
    width: 60px !important;
    right: 0;
    left: 0;
    width: auto;
    min-width: auto;
    margin: 10px auto;
    position: relative;
  }
ion-content .bottom .logo-ridotto {
  width: 100% !important
}
ion-content .bottom .logo-ridotto div {
  margin: 0;
}
  ion-content .bonuses-container {
    margin: 0em auto;
  }
  ion-content .bonus-img1, ion-content .bonus-img2 {
    height: 260px;
  }
  ion-content .bonuses-container .bonus h3 {
    font-size: 24px;
    text-align: center;
  }
  ion-content .bonuses-container .bonus h4, ion-content .bonuses-container .bonus h5 {
    font-size: 18px;
    line-height: 1.5rem;
    text-align: center;
  }
  ion-content .main-page .bg-container {
    right: -5%;
    height: 400px;
    width: 100%;
  }
  #react-root ion-content .bg-container {
    right: auto;
    top: auto;
    width: 100%
  }
  ion-content .live-casino-container .pic-123 {
    right: -46%;
  }
  ion-content .table-games-container .pic-33 {
    right: -43%;
  }
  ion-content .terms .h3-smaller {
    font-size: 24px;
  }
  /* ion-content .banner-single {
    margin-bottom: 0px;
  } */
  ion-content .jackpot-bottom p {
    font-size: 12px;
    /* margin-right: 1em; */
  }
  ion-content .jackpot-bottom {
    margin: 0 auto;
    margin-top: 1em;
  }
  .user-container {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
  }
  /* .regg .banner-info {
    display: none;
  } */
  ion-content .login-modal.register {
    max-width: 100vw;
  }
  ion-content .register .modal-container-title, ion-content .register .modal-container-title-second {
    font-size: 100px;
    margin-top: 0em;
  }
  ion-content .register .modal-container {
    margin: 0 auto;
        width: 90%;
  }
  ion-content .step-input-container {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  ion-content .register .dates {
    max-width: 68%;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  ion-content .register .date {
    max-width: 60px;
  }
  ion-content .register .dates ion-select, ion-content .register .mobile {
    margin: 0;
  }
  ion-content .register .step .header-search {
    max-width: 165px;
  }
  ion-content .register .date {
    max-width: 60px !important;
  }
  ion-content .register .step .mobilecode {
    max-width: 87px;
  }
  ion-content .register .step .mobile {
    max-width: 120px;
    margin-left: 10px;
  }
  ion-content  .register .custom-block {
    width: auto;
    max-width: none;
  }
  ion-content .step-input-container .flag-select__options {
    max-height: 305px !important;
    top: -200px !important;
  }
  ion-content .register .annotation {
    margin-left: 0;
  }
  .links-to-menu ion-item {
    margin-left: 1em;
  }
  .new-menu ion-item {
    opacity: 0;
    -webkit-transition: .3s;
    transition: .3s;
  }
  .minimized, .hide-this {
    display: none !important;
  }
  .new-menu {
    -webkit-transition: .3s;
    transition: .3s;
    opacity: 0;
  }
  .new-menu .new-menu-active, .new-menu-active {
    opacity: 1;
  }
  #user-menu {
    display: none !important;
  }
  .search-game ion-label {
    text-align: end;
  }
  ion-content .user-cabinet-container {
    margin-top: 50px;
  }
  ion-content .shade .top-shade {
    font-size: 90px;
    color: #F2F2F2;
    height: auto;
    margin: 0;
    margin-left: 0.5em;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -moz-user-select: none;
     -ms-user-select: none;
         user-select: none;
    z-index: 1;
  }
  ion-content .shade .top-shade img {
    width: 60px;
    height: 60px;
  }
  ion-content .shade .deposit-icon, .shade .balance-icon {
    width: 65px;
    height: 65px;
  }
  ion-content .shade .bottom-shade {
      font-size: 37px;
      color: #F2F2F2;
      margin: 0;
      margin-top: -0.8em;
      margin-left: 100px;
      height: auto;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -moz-user-select: none;
       -ms-user-select: none;
           user-select: none;
      z-index: 1;
  }
  ion-content .user-cabinet-container .shade {
    bottom: 0;
  }
  ion-content .user-cabinet-container {
    width: 90%;
    margin: 0 auto;
  }
  .deposit-money, .transfer-money, .withdraw-money, .funds-security, .transactions {
    margin-left: 0% !important;
    width: 100%;
    padding-bottom: 10em;
  }
  ion-content .account-details-container {
    width: 100%;
  }
  .title{
    font-size: 42px;
    margin-top: 2em;
  }
  ion-content .account-details-container .step-input-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  ion-content .deposit {
    font-size: 18px;
  }
  ion-content .transfer-container {
    margin-top: 35px;
  }
  .transfer-money .submit {
    margin-left: auto;
  }
  ion-content .socials div {
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    -webkit-box-flex: 0;
        -ms-flex: 0 1 50px;
            flex: 0 1 50px;
    min-height: 48px;
    min-width: 48px;
    margin: 10px;
    border-radius: 17px;
  }
    ion-content .socials {
      height: 80px;
  }
  ion-content .funds-security-container title{
    font-size: 37px;
  }
  ion-content .funds-security {
    padding-bottom: 5em;
  }
  ion-content .funds-security-container {
        width: 100%;
  }
  ion-content .DayPickerInput input {
    margin-left: 0;
  }
  ion-content .transactions .submit {
    margin-left: 0;
    margin-top: 2em;
  }
  ion-content .shade {
    right: 0;
  }
  .InputFromTo .DayPickerInput-Overlay {
    width: 90vw !important;
    z-index: 999999;
  }
  .InputFromTo-to .DayPickerInput-Overlay{
    margin-left: 0px !important;
  }
  .transactions .card-table {
    width: 100%;
  }
  .transactions .card-body {
    width: 100%;
  }
  .transactions .table th {
    padding: 0.3em;
  }
  .user-cabinet-container .account-details-container{
    margin: 0 auto;
  }
  .user-cabinet-container .account-details-container .header-search {
    max-width: 225px;
  }
  .user-cabinet-container .dates .header-search {
    margin-left: 30px;
    margin-right: 0px;
  }
  .account-details-container .lower {
    margin-top: 2em;
  }
  .modal-container-title {
    font-size: 38px !important;
  }
  .title-2 {
    margin: 0 !important;
  }
  .games-limit ion-select{
    max-width: 110px !important;
  }
  .games-limit .custom-limit {
    max-width: 110px !important;
  }
  .games-limit .custom-block {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
  }
  .games-limit  .mobilecode {
    margin-right: 0 !important;
    margin-left: 10px;
  }
  ion-content .verify p {
    font-size: 16px;
  }
  ion-content .verify .step-input-container {
    margin-left: 0;
  }
  ion-content .previews-container {
    width: 100%;
  }
  ion-content  .file-zone-container {
    width: 100%;
  }
  ion-content .login-modal .incorrect-password {
        text-align: center;
  }
  ion-content .banner-container {
    margin-bottom: 0;
  }
  ion-content .jackpot-bottom {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    max-width: 100%;
  }
  .jackpot-links.bnss {
    -ms-flex-pack:distribute;
        justify-content:space-around;
  }
  #video-slots .main-box, #table-games .main-box, #live-casino .main-box, #other .main-box, #lotto .main-box {
    max-width: 220px !important;
    width: 100% !important;
    height: 169px !important;
    opacity: 1;
    z-index: 99999;
  }
  #video-slots p, #table-games p, #live-casino p, #other p, #lotto p{
    margin-top: -1.5em !important;
  }
  .header-md {
    z-index: 999 !important;
  }
  ion-content .favorites-container .slick-next {
    right: -10px;
  }
  ion-content .favorites-container .slick-prev {
    left: -10px;
  }
  .video-slots-container, .table-games-container, .live-casino-container {
      max-width: 378px !important;
  }
  ion-content ion-header {
    display: none !important;
  }
  .mobile-header {
    position: sticky;
    width: 100%;
    z-index: 999999 !important;
    background: #fff !important;
    border-bottom: 1px solid #888888;
    top: 0 !important;
    left: 0 !important;
  }
  .banner-container {
    z-index: 10 !important
  }
  .video-slots-container, .table-games-container, .live-casino-container {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }
   #video-slots, .add-games, #table-games, #live-casino{
     width: 100% !important;
     -webkit-box-flex: 1 !important;
         -ms-flex: 1 0 100% !important;
             flex: 1 0 100% !important;
   }
   .video-slots-container .game, .table-games-container .game, .live-casino-container .game {
     -webkit-box-flex: 0 !important;
         -ms-flex: 0 0 47% !important;
             flex: 0 0 47% !important;
     /* height: 190px !important; */
   }
   .game.doubled {
     -webkit-box-flex: 1 !important;
         -ms-flex: 1 0 90% !important;
             flex: 1 0 90% !important;
     max-width: 98% !important;
      height: 250px !important;
   }
   .video-slots-container .game .main-box, .table-games-container .game .main-box, .live-casino-container .game .main-box {
     /* max-height: 130px !important; */
   }
   .game.doubled .main-box {
      /* max-height: 75% !important */
   }
   #video-slots p, #table-games p, #live-casino p, #other p {
    margin-top: -0.5em !important;
  }
   #lotto p {
    margin-top: 0em !important;
  }
  ion-content .sc-chat-window.opened {
    z-index: 9999;
    right: 0px;
    bottom: 0px;
  }
  .video-slots-container .game,
  .table-games-container .game,
  .live-casino-container .game {
    margin-top: 5px !important;
    margin-bottom: 5px !important;
  }
}
@media screen and (min-width: 760px) and (max-width: 780px) {

  .video-slots-container, .table-games-container, .live-casino-container {
    grid-template-columns: repeat(2, 1fr) !important;
    max-width: 100% !important;
  }
  .add-games, .game {
    max-width: 325px  !important;
  }
  .game.doubled {
    max-width: 698px !important;
    height: 380px !important;
  }
  ion-content .game.doubled .main-box {
    max-height: 312px;
  }
  .favorites-container .slick-list {
    max-width: 86.5%;
  }
  ion-content .favorites-container .slick-slide {
    /* margin-left: 10px;
    margin-right: 10px; */
  }
  ion-content .favorites-container .slick-slider .slick-track {
    /* left: 300px !important; */
  }

}
@media screen and (max-width: 900px)  and (orientation: landscape) {
    ion-content .video-slots-container,  ion-content .table-games-container,  ion-content .live-casino-container {
    grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)) !important;
  }
  #react-root .game-modal .modal-wrapper {
    width: 100vw !important;
    height: 100%;
}
}
@media screen and (max-width: 320px) {
  .jackpot-links a p {
    font-size: 10px !important;
  }
  .account-details-container .submit {
    margin-right: 10% !important;
  }
  ion-content .game .game-preview {
    height: 80px !important;
  }
}
@media screen and (max-width: 400px) {
  ion-content .game .game-preview {
    height: 95px !important;
  }
  .doubled.game .game-preview {
    height: 100% !important
  }
}
@media screen and (max-width: 550px) {
  .premium-container ul {
    padding-left: 20px;
  }
  .doubled.game .game-preview {
    height: 100% !important
  }
  .favorites-container .slick-list {

        height: auto;
  }
    ion-content .spinner-box .logo {
    height: 60px;
  }
  ion-content .premium-container .card-table {
    width: 81%;
  }
  .jackpot-title .jackpot-playnow {
    margin-left: 1em;
  }
  .jackpot-text p {
    font-size: 16px !important;
  }
  .jackpot-text {
    width: 100%;
  }
  .jackpot-title .jackpot-playnow {
    width: 94px;
    height: 30px;
  }
  #react-root .game-modal .modal-wrapper {
    width: 100vw !important;
    height: 100% !important
  }
  #react-root .close-gme {
    width: 40px;
    height: 40px;
    right: 5px;
    top: 5px;
  }
  .mobile-view .header-menu-bottom {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }
  .carousel-root .carousel .control-dots {
        bottom: 30px;
  }
  .tour .single-tournament {
        width: 80%;
  }
  .tour .single-tournament h3 {
    text-align: center;
    line-height: 33px;
    font-size: 29px;
  }
  .single-tournament p, .single-tournament li {
    font-size: 16px;
  }
  .single-tournament .tournir-date-start, .single-tournament .tournir-date-end, .single-tournament .tournir-date-timer {
        font-size: 16px;
  }
  .no-tabs .sticky {
    display: none
  }
  .hide-mobile {
    display: none;
  }
    ion-content .bottom .container {
      margin-left: 2em;
      width: 87%;
      margin-bottom: 1em;
    }
    ion-content .register .step-input-title {
      font-size: 16px;
    }
  #react-root .register-img {
    width: 57vw;
    opacity: .3;
  }
  #react-root .bonuses-container .pic-123 {
    top: auto;
    right: auto
  }
  .outer-links img {
    height: 37px !important;
  }
  #react-root ion-content .winners-container .bonus img {
    max-width: 282px;
    max-height: 282px;
  }
  .themeToggle.only-mobile {
    position: absolute;
    right: 5px;top:6px;
  }
  .sticky {
    left: 0;
    bottom: 106px;
    width: 33px;
    height: 200px;
    -ms-flex-pack: distribute;
        justify-content: space-around;
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-overflow-scrolling: auto;
  }
  .sticky div {
    position: sticky;
    left: -47px;
    z-index: 999999;
    margin: 0;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }
  .sticky .sign-up {
    bottom: 150px;
  }
  .sticky .login {
    bottom: 100px;
    margin-bottom: -10px;
  }
  .sticky .help-sticky {
    display: none;
  }
  #react-root .carousel .slider-wrapper.axis-horizontal .slider .slide {
        margin-bottom: 2em;
  }
  #providers .provider-card {
    width: 160px;
    height: 190px;
}
}
@media screen and (max-width: 800px){
  ion-content .user-cabinet-container .chart-bars{
    margin-bottom: 0px;
  }
  .doubled.game .game-preview {
    height: 100% !important
  }
  .user-cabinet-container .pies-container {
    margin-top: 0;
  }
  ion-content  .user-cabinet-container .charts-container {
    margin-top: 0;
  }
  .rcw-conversation-container {
      height: 80vh !important;
  }
  .rcw-widget-container {
    height: auto !important;
  }
  h1.favs {
    font-size: 30px;
  }
  .footer-container .logo-ridotto .eighteen   {
    min-width: 80px;
    margin: 0 !important;
  }
  ion-content .bottom .logo-ridotto {
    /* width: 110px !important; */
    margin: 0 !important
  }
  ion-content .footer-container .bottom {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding-bottom: 1em
  }
  ion-content .bottom .eighteen {
    margin: 0;
  }
}
/* @media screen and (max-width: 768px) {
    html {
    height: 100.1vh !important;
    max-height: none !important;
    overflow: scroll !important;
  }
} */
