/* Ionic Variables and Theming. For more info, please see:
http://ionicframework.com/docs/theming/ */

/** Ionic CSS Variables **/
:root {
  /** primary **/
  --ion-color-contrast: #FFFFFF;
  --ion-color-orange: #fe9c26;
  --ionicon-stroke-width: 40;
  --ion-color-primary: #3880ff;
  --card-color: #fff;
  --ion-reg-opacity: 1;
  --bold-black: #000;
  --ion-dark: #F2F2F2;
  --ion-color-primary-rgb: 56, 128, 255;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3171e0;
  --ion-color-primary-tint: #4c8dff;
  --ion-color-bets: #C11916;
  --ion-color-banner: #C11916;
  --backdrop-opacity: .9;
  --ion-backdrop-color: #FFFFFF;
  --ion-login-bg: #FFFFFF;
  --ion-login-header:  #F2F2F2;
  --ion-register-link-weight: normal;
  --ion-login-title: #444444;
  --primary-text: #444444;
  --svg-color: #444444;
  --color: #444444;
  --ion-login-forget: #444444;
  --ion-register-banner: url('../images/register.png');
  --ion-register-default: #444444;
  --ion-footer: #F2F2F2;
  --ion-register-check: #444444;
  --ion-register-link: #C11916;
  --ion-placeholder-default: #888888;
  --ion-background-color2: #FFFFFF;
  --ion-livecasino: url("../images/livecasino(1).png");
  --login-bg: url("../images/Cards.svg");
  --ion-semitransparent: rgba( 255, 255, 255, 0.7);
  --ion-semitransparent9: rgba( 255, 255, 255, 0.9);
  --ion-typee: grey;
  --ion-header: #FFF;
  --ion-background-color: #ffffff;
  --layer: transparent linear-gradient(180deg, transparent 46%, #FFFFFF 69%) 0% 0% no-repeat padding-box;
  --ion-banner-back: linear-gradient(0deg, rgba(255,255,255,1) 1%, rgba(255,255,255,0.7707457983193278) 2%, rgba(218,218,218,1) 32%, rgba(218,218,218,1) 59%, rgba(255,255,255,0.896796218487395) 100%);
  /* --ion-banner-back: transparent linear-gradient(180deg, #FFFFFF 0%, #F5F5F5 50%, #E5E5E5 100%) 0% 0% no-repeat padding-box; */

  /** secondary **/;
  --ion-color-secondary: #3dc2ff;
  --ion-color-secondary-rgb: 61, 194, 255;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #36abe0;
  --ion-color-secondary-tint: #50c8ff;

  /** tertiary **/
  --ion-color-tertiary: #5260ff;
  --ion-color-tertiary-rgb: 82, 96, 255;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #4854e0;
  --ion-color-tertiary-tint: #6370ff;

  /** success **/
  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  /** warning **/
  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  /** danger **/
  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  /** dark **/
  --ion-color-dark: #222428;
  --ion-color-dark-rgb: 34, 36, 40;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #1e2023;
  --ion-color-dark-tint: #383a3e;

  /** medium **/
  --ion-color-medium: #92949c;
  --ion-color-medium-rgb: 146, 148, 156;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #808289;
  --ion-color-medium-tint: #9d9fa6;

  /** light **/
  --ion-color-light: #f4f5f8;
  --ion-color-light-rgb: 244, 245, 248;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d7d8da;
  --ion-color-light-tint: #f5f6f9;
}
.ion-color-orange {
    --ion-color-base: var(--ion-color-orange) !important;
  }
body.dark {
  /* Dark mode variables go here */
  --ion-login-bg: #888888;
  --layer: transparent;
  --bold-black: #F2F2F2;
  --ion-reg-opacity: 0.28;
  --ion-register-check: #F2F2F2;
  --ion-register-link: #F2F2F2;
  --ion-register-link-weight: bold;
  --ion-background-color: #27292d;
  --ion-background-color-rgb: 0,0,0;
  --ion-text-color: #ffffff;
  --ion-typee: #ffffff;
  --ion-text-color-rgb: 255,255,255;
  --ion-color-step-50: #0d0d0d;
  --ion-color-step-100: #1a1a1a;
  --ion-color-step-150: #262626;
  --ion-color-step-200: #333333;
  --ion-color-step-250: #404040;
  --ion-color-step-300: #4d4d4d;
  --ion-color-step-350: #595959;
  --ion-color-step-400: #666666;
  --ion-color-step-450: #737373;
  --ion-color-step-500: #808080;
  --ion-color-step-550: #8c8c8c;
  --ion-color-step-600: #999999;
  --ion-color-step-650: #a6a6a6;
  --ion-color-step-700: #b3b3b3;
  --ion-color-step-750: #bfbfbf;
  --ion-color-step-800: #cccccc;
  --ion-color-step-850: #d9d9d9;
  --ion-color-step-900: #e6e6e6;
  --ion-color-step-950: #f2f2f2;
  --ion-toolbar-background: #FFF;
  --card-color:#45474A;
  --ion-item-background: #27292d;
  --ion-color-banner: #FFF;
  --ion-backdrop-color: #27292d;
  --ion-login-header:  #444444;
  --ion-login-title: #FFF;
  --ion-login-forget: #FFFFFF;
  --ion-register-banner: url('../images/register-black.png');
  --ion-register-default: #FFFFFF;
  --ion-placeholder-default: #FFFFFF;
  --ion-background-color2: #141414;
  --ion-livecasino: url("../images/livecasino(2).png");
  --ion-semitransparent: rgba( 39,41,45, 0.7);
  --ion-semitransparent9: rgba( 39,41,45, 0.9);
  --ion-banner-back: #27292d;
  --primary-text: #FFF;
  --svg-color: rgba( 255,255,255, 0.7);
  --ion-footer: rgba( 82,84,87, 0.7);
  --ion-dark: #45484A;
  --ion-header: #F2F2F2;
}
body.dark #banner-main {
  content: url("../images/nightbanner.png");
}
body.dark .banner-info {
  background: transparent linear-gradient(180deg, grey -30%, #000 41%) 100% 108% no-repeat padding-box
}
:host(.ion-color) .toast-wrapper {
    background: green !important;
}
